<div class="dialog-header">
    <div>{{ data.name }} Info</div>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>
<div class="dialog-content" #content>
    <div *ngFor="let item of data.properties | keyvalue" class="data-property">
        <div class="section">
            <p class="metadata-label">{{ item.key.toString() | replaceCharacter }}</p>
            <p class="metadata-text">{{ item.value || 'Not Available' }}</p>
        </div>
    </div>
</div>
