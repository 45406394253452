
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenDatapointType } from './GenDatapointType';
import { GenUploadedFile } from './GenUploadedFile';
import * as moment from 'moment';

export class GenDatapoint {

    id: number;
    active: boolean;
    boundary: string;
    description: string;
    documents: GenUploadedFile[];
    intersectingStates: string[];
    key: number;
    name: string;
    type: GenDatapointType;
    updatedDate: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genDatapoint: GenDatapoint);
    constructor( id: number );

    constructor( input?: GenDatapoint|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.active = input.active; // boolean [boolean]
            this.boundary = input.boundary; // string [string]
            this.description = input.description; // string [string]
            if ( input.documents && input.documents.map ) { // list [GenUploadedFile]
                this.documents = input.documents.map( ( x: GenUploadedFile ) => {
                    return new GenUploadedFile( x );
                } );
            } else {
                this.documents = input.documents;
            }
            this.intersectingStates = input.intersectingStates; // list [string]
            this.key = input.key; // int [number]
            this.name = input.name; // string [string]
            this.type = (typeof input.type === 'string') ? GenDatapointType.forName(<string> input.type) : <any> input.type; // GenDatapointType
            if ( input.updatedDate && typeof input.updatedDate === 'string' ) {
                this.updatedDate = moment(input.updatedDate).utc();
            } else {
                this.updatedDate = input.updatedDate;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenDatapoint[] {
        if (arr && arr.map) {
          return arr.map(a => new GenDatapoint(a));
        } else {
          return [];
        }
    }
}

