import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LiteRoutingModule} from './lite-routing.module';
import {MapLiteComponent} from './components/map-lite/map-lite.component';
import {SharedModule} from 'frontend/src/shared/shared.module';
import {MapLayoutLiteComponent} from './components/map-layout-lite/map-layout-lite.component';
import {MapModule} from '../map/map.module';

@NgModule({
    declarations: [MapLiteComponent, MapLayoutLiteComponent],
    imports: [CommonModule, LiteRoutingModule, MapModule, SharedModule],
    providers: [],
    exports: [MapLiteComponent],
})
export class LiteModule {}
