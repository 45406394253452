<div class="dialog-header">
    <span>Date Range</span>
    <button mat-icon-button matDialogClose>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>
<div class="dialog-content" #content>
    <div>
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>Start Date</mat-label>
            <input
                matInput
                [matDatepicker]="startDatepicker"
                [(ngModel)]="startDate"
                (dateChange)="setStartDate($event)"
                readonly
                [min]="minDate"
                [max]="endDate" />
            <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatepicker [startAt]="startDate" disabled="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
            <mat-select (selectionChange)="changeStartTime($event.value)" [(ngModel)]="startTime">
                <mat-option *ngFor="let step of timeSteps" [value]="step" [disabled]="!validStartTime(step)"
                    >{{ step.display }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>End Date</mat-label>
            <input
                matInput
                [matDatepicker]="endDatepicker"
                [(ngModel)]="endDate"
                (dateChange)="setEndDate($event)"
                readonly
                [min]="startDate"
                [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatepicker disabled="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
            <mat-select (selectionChange)="changeEndTime($event.value)" [(ngModel)]="endTime">
                <mat-option *ngFor="let step of timeSteps" [value]="step" [disabled]="!validEndTime(step)">{{ step.display }} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="dialog-actions">
    <button mat-flat-button color="primary" (click)="updateFilters()">Update</button>
</div>
