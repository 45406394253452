import {GenMapLayerPopover} from '../../../../../generated/serverModels/GenMapLayerPopover';
import {GenPopoverDataType} from '../../../../../generated/serverModels/GenPopoverDataType';

export class MapLayerPopover extends GenMapLayerPopover {
    constructor(data?: MapLayerPopover) {
        super(data);
    }

    public isLink(): boolean {
        return this.type === GenPopoverDataType.LINK || this.type === GenPopoverDataType.DOWNLOAD;
    }

    public isModal(): boolean {
        return this.type === GenPopoverDataType.MODAL;
    }
}
