<div class="dialog-header">
    <div class="filter-header">Summary Settings</div>
    <mat-icon inline class="clickable" fontSet="fas" fontIcon="fa-times" (click)="dialogRef.close()"></mat-icon>
</div>

<div #content class="dialog-content">
    <span class="dialog-description"> View high-level summary data aggregated by county, state, or utility. </span>

    <div class="filter-bar">
        <mat-button-toggle-group [value]="selectedAggregation">
            <mat-button-toggle *ngFor="let level of aggregationLevels" [value]="level.name" (change)="changeAggregationLevel(level)">
                {{ level | aggregationLevel }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>

<div class="dialog-actions">
    <button mat-flat-button color="primary" (click)="configureWidget()" [disabled]="disableUpdate()">Update Widget</button>
</div>
