import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IMinMax} from './min-max.interface';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {minMaxValidator} from './min-max.validator';
import {debounce, filter, tap} from 'rxjs/operators';
import {interval} from 'rxjs';
import {IFilterComponentInterface} from '../../interfaces/filter.interface';
import {INumericValues} from '../../interfaces/filter-type.interface';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

const numberRegEx = new RegExp('^[+-]?(([0-9]*[.])?[0-9]+)$');

@Component({
    selector: 'eaglei-numeric-range-filter',
    templateUrl: './numeric-range-filter.component.html',
    styleUrls: ['./numeric-range-filter.component.scss'],
})
export class NumericRangeFilterComponent implements IFilterComponentInterface<IMinMax> {
    @Input() title: string = 'placeholder';
    @Input() debounceTime: number = 250;
    @Input() params: INumericValues = {
        allowNegative: true,
        allowDecimal: false,
    };
    @Output() filterChange = new EventEmitter<IMinMax>();

    public isMinActive: boolean = false;
    public isMaxActive: boolean = false;

    filterForm = new FormGroup(
        {
            minValue: new FormControl<number>(null, [Validators.pattern(numberRegEx)]),
            maxValue: new FormControl<number>(null, [Validators.pattern(numberRegEx)]),
        },
        {
            validators: minMaxValidator,
        }
    );

    constructor() {
        this.filterForm.valueChanges
            .pipe(
                tap((value) => {
                    this.isMinActive = !isNaN(getValue(value.minValue));
                    this.isMaxActive = !isNaN(getValue(value.maxValue));
                }),
                filter(() => this.filterForm.valid),
                debounce(() => interval(this.debounceTime)),
                takeUntilDestroyed()
            )
            .subscribe((formObject) => {
                this.filterChange.emit({
                    min: getValue(formObject.minValue),
                    max: getValue(formObject.maxValue),
                });
            });
    }
}

function getValue(x: any) {
    let ret: number;
    if (isNaN(x)) {
        ret = NaN;
    } else if (x === null) {
        ret = NaN;
    } else if (x === undefined) {
        ret = NaN;
    } else {
        ret = x;
    }
    return ret;
}
