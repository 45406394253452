<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend status-legend">
            <div class="circle-container">
                <ng-container [ngTemplateOutlet]="blueCircle"></ng-container>
                <span class="label"> Future Oil Planned Outages</span>
            </div>
            <div class="circle-container">
                <ng-container [ngTemplateOutlet]="greenCircle"></ng-container>
                <span class="label"> Active Oil Planned Outages</span>
            </div>
            <div class="circle-container">
                <ng-container [ngTemplateOutlet]="grayCircle"></ng-container>
                <span class="label"> Past Oil Planned Outages</span>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #blueCircle let-size="size">
    <div class="status future" [ngClass]="{'small-circle': size === 'small'}">
        <mat-icon fontSet="fas" fontIcon="fa-wrench"></mat-icon>
    </div>
</ng-template>

<ng-template #greenCircle let-size="size">
    <div class="status active" [ngClass]="{'small-circle': size === 'small'}">
        <mat-icon fontSet="fas" fontIcon="fa-wrench"></mat-icon>
    </div>
</ng-template>

<ng-template #grayCircle let-size="size">
    <div class="status past" [ngClass]="{'small-circle': size === 'small'}">
        <mat-icon fontSet="fas" fontIcon="fa-wrench"></mat-icon>
    </div>
</ng-template>
