import {Component, TemplateRef, TrackByFunction, ViewChild, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {GenAlert} from 'frontend/generated/serverModels/GenAlert';
import {GenCounty} from 'frontend/generated/serverModels/GenCounty';
import {AuthenticationService} from 'frontend/src/app/services/authentication.service';
import {DataService} from 'frontend/src/app/services/data.service';
import {State} from '../../../outage/classes/state';
import {OutageService} from '../../../outage/services/outage.service';
import {LocationNumbers} from '../../classes/location-numbers';
import {UserAlert} from '../../classes/user-alert';
import {AlertService} from '../../services/alert.service';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {KeyValue} from '@angular/common';
import {NumeralPipe} from 'frontend/src/shared/pipes/numeral.pipe';
import {forkJoin, of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';

@Component({
    selector: 'eaglei-user-defined-alert-modal',
    templateUrl: './user-defined-alert-modal.component.html',
    styleUrls: ['./user-defined-alert-modal.component.scss'],
    providers: [NumeralPipe],
})
export class UserDefinedAlertModalComponent {
    public dialogRef = inject(MatDialogRef<UserDefinedAlertModalComponent>);
    protected dialog = inject(MatDialog);
    protected data: {
        userAlerts: UserAlert[];
        countyNumbers: LocationNumbers[];
        femaNumbers: LocationNumbers[];
        stateNumbers: LocationNumbers[];
    } = inject(MAT_DIALOG_DATA);
    protected alertService = inject(AlertService);
    protected outageService = inject(OutageService);
    protected auth = inject(AuthenticationService);
    @ViewChild('invalidDialog') invalidDialog!: TemplateRef<unknown>;
    protected femaPipe = inject(NumeralPipe);
    public femaRegions: number[] = [];
    public states: State[] = [];

    /** Total covered customers for each Fema region */
    protected femaNumbers: {[femaId: number]: number} = {};
    /** Total covered customers for each state */
    protected stateNumbers: {[stateId: number]: number} = {};
    /** Total covered customers for each county */
    protected countyNumbers: {[countyId: number]: number} = {};

    private userId: number;

    /** List of all active alert subscription */
    private userAlerts: UserAlert[] = [];

    /** FormGroup containing forms for each alert location */
    protected alertControls = new FormGroup<{
        [alertId: string]: FormGroup<{
            applyToStates: FormControl<boolean>;
            county: FormControl<GenCounty[]>;
            location: FormControl<number | State>;
            threshold: FormControl<number>;
            thresholdType: FormControl<string>;
        }>;
    }>({});
    /** Cache of all counties for each state, retrieved when state is selected, to prevent repetitive api calls */
    protected countyCache: {[stateId: number]: GenCounty[]} = {};
    /** Map of each form's selected State, to determine whether to show the county selection & to retrieve the relevant counties from cache */
    protected selectedState: {[formId: number]: number} = {};

    constructor() {
        this.states = DataService.states.value;
        this.states.sort((a, b) => (a.name > b.name ? 1 : -1));

        this.femaRegions = DataService.femaRegions.value.map((r) => r.dotregion);
        this.femaRegions.sort((a, b) => a - b);

        // TODO this should just use authenticated User
        this.userId = this.auth.authenticatedUser.getValue().id;

        this.data.countyNumbers.forEach((number) => (this.countyNumbers[number.locationId] = number.coveredCustomers));
        this.data.femaNumbers.forEach((number) => (this.femaNumbers[number.locationId] = number.coveredCustomers));
        this.data.stateNumbers.forEach((number) => (this.stateNumbers[number.locationId] = number.coveredCustomers));

        this.userAlerts = this.data.userAlerts;
    }

    ngOnInit() {
        this.addLocation();
    }

    public addLocation(): void {
        const newGroup = new FormGroup(
            {
                applyToStates: new FormControl(false),
                county: new FormControl([]),
                location: new FormControl(undefined, [Validators.required]),
                threshold: new FormControl(undefined, [Validators.required, Validators.min(1)]),
                thresholdType: new FormControl(undefined, Validators.required),
            },
            {validators: validateThreshold}
        );
        this.alertControls.addControl(`${new Date().getTime()}`, newGroup);
    }

    public addAlert(): void {
        const formGroup = this.alertControls.value;

        const alerts: UserAlert[] = [];
        const invalid: (UserAlert | {reason: string})[] = [];
        for (const key in formGroup) {
            if (key in formGroup) {
                const {applyToStates, county, location, threshold, thresholdType} = formGroup[key];
                const counties = !!county[0] ? county : county.slice(1); // filters undefined 'select-all' value

                const matches =
                    (a1: UserAlert) =>
                    (ua: UserAlert): boolean =>
                        ua.details.includes(a1.detailIds[0]) && ua.threshold === a1.threshold && ua.applyToStates === a1.applyToStates;

                // Loop at least once when no counties, because it's a state/region alert
                for (let i = 0; i < (counties.length || 1); i++) {
                    const c = counties[i];
                    const alert = this.generateAlert(applyToStates, c, location, threshold, thresholdType);

                    if (this.userAlerts.some(matches(alert)) || (alert.thresholdType === 'percent' && isNaN(alert.threshold))) {
                        const reason = isNaN(alert.threshold) ? 'Invalid threshold' : 'Preexisting subscription';
                        alert.threshold = threshold; // reset threshold value to match user input
                        invalid.push({...alert, reason});
                    } else {
                        alerts.push(alert);
                    }
                }
            }
        }

        if (invalid.length) {
            this.dialog.open(this.invalidDialog, {
                data: {invalid},
            });
        }
        // Make all alert creation calls
        forkJoin(
            alerts.map((alert) =>
                this.alertService.createUserAlert(new GenAlert(alert)).pipe(catchError((err) => of({data: alert, failure: true})))
            )
        )
            .pipe(
                map(this.handleAlertFails, this),
                // once all alerts are created, then create all the alert details
                concatMap((res) => forkJoin(res.map((r, i) => this.alertService.createUserAlertDetail(r.data.id, alerts[i].detailIds)))),
                // once all details are created, then subscribe to all alerts
                concatMap((res) => forkJoin(res.map((alertId) => this.alertService.subscribeUserAlert(alertId, [this.userId]))))
            )
            .subscribe((res) => this.dialogRef.close());
    }

    /**
     * Generates an alert from form values
     * @param applyToStates UserAlert's `applyToStates` value
     * @param county Optionally selected county
     * @param location State or region number
     * @param threshold Outage threshold value
     * @param thresholdType Type of outage threshold
     * @returns UserAlert
     */
    protected generateAlert(
        applyToStates: boolean,
        county: GenCounty | undefined,
        location: number | State,
        threshold: number,
        thresholdType: string
    ): UserAlert {
        const locationName = this.getLocationName(location, true);

        const alert = new UserAlert(undefined, []);
        let newThreshold: number;

        alert.county = county;
        alert.description = county?.name;
        alert.applyToStates = typeof location === 'number' ? applyToStates : false;
        alert.detailIds = [county?.id ?? location['id'] ?? location];
        alert.location = location;
        alert.locationName = locationName;
        alert.ownerId = this.userId;

        newThreshold = this.getThreshold(threshold, thresholdType, county ?? location, !!county);
        alert.threshold = newThreshold;
        alert.thresholdType = thresholdType;

        if (county) {
            alert.name = `${county.name}${typeof location === 'number' ? '' : ` (${location.abbreviation})`}`;
            alert.type = 'county';
        } else {
            alert.type = typeof location === 'number' ? 'region' : 'state';
            alert.name = typeof location !== 'number' ? locationName : `FEMA Region ${this.getLocationName(location)}`;
        }

        if (alert.type === 'region') {
            alert.rollUp = !alert.applyToStates;
        }

        return alert;
    }

    /** Returns a State's name, or region number converted to a Fema region name. */
    protected getLocationName(location: number | State, addPrefix = false) {
        if (typeof location !== 'number') {
            return location?.name;
        }
        return this.femaPipe.transform(location, addPrefix);
    }

    /** Removes a location from the main form */
    public removeForm(formId: string): void {
        this.alertControls.removeControl(formId);
    }

    /** On location selection, handles displaying county select, retrieving counties, & resetting the thresholdtype */
    public updateLocation(event: MatSelectChange, formId: string): void {
        const form = this.alertControls.controls[formId];
        form.controls.county.reset([]);
        const location = form.controls.location.value;

        let total: number;
        if (typeof location === 'number') {
            total = this.femaNumbers[location];
            // reset the selected state map in order to hide the county selection
            this.selectedState[formId] = undefined;
        } else {
            total = this.stateNumbers[location.id];

            if (!this.countyCache[location.id]) {
                this.outageService.getCountiesForStates([location]).subscribe((counties) => {
                    this.countyCache[location.id] = counties.sort((a, b) => (a.name > b.name ? 1 : -1));
                });
            }

            this.selectedState[formId] = location.id;
        }

        if (total === 0) {
            form.controls.thresholdType.reset('outage');
        }
    }

    /** Returns if outage, or calculates total number of outages from percentage */
    private getThreshold(threshold: number, thresholdType: string, location: number | State | GenCounty, isCounty = false): number {
        if (thresholdType === 'outage') {
            return threshold;
        }

        let total = 0;
        if (!isCounty) {
            total = typeof location === 'number' ? this.femaNumbers[location] : this.stateNumbers[location.id];
        } else if (typeof location !== 'number') {
            total = this.countyNumbers[location.id];
        }

        const percent = threshold / 100;
        return Math.floor(total * percent);
    }

    private handleAlertFails<T extends {data: UserAlert; failure?: boolean; metadata: unknown}>(alerts: T[]): T[] {
        const failedAlerts: UserAlert[] = [];
        const successfulAlerts: T[] = [];

        alerts.forEach((alert) => {
            if ('failure' in alert) {
                failedAlerts.push(alert.data);
            } else {
                successfulAlerts.push(alert);
            }
        });

        if (failedAlerts.length) {
            this.dialog.open(this.invalidDialog, {
                data: {invalid: failedAlerts},
            });
        }

        return successfulAlerts;
    }

    /** Whether to show the percent option in thresholdtype. */
    public showPercentOption(formId: string): boolean {
        const {county, location} = this.alertControls.controls[formId].controls;
        if (location.value === null || location.value === undefined) {
            return true;
        }

        if (county.value?.length) {
            return county.value.some((c) => this.countyNumbers[c?.id] > 0);
        }
        return (typeof location.value === 'number' ? this.femaNumbers[location.value] : this.stateNumbers[location.value.id]) > 0;
    }

    trackByKey: TrackByFunction<KeyValue<string, unknown>> = (i, item) => item.key;
    trackByStateId: TrackByFunction<State> = (i, state) => state.id;
}

/** Function to validate that a "percent" threshold is not above 100%. */
function validateThreshold(control: FormGroup): ValidationErrors | null {
    const {threshold, thresholdType} = control.controls;
    if (thresholdType.value === 'percent') {
        if ((threshold.value ?? 0) > 100) {
            threshold.setErrors({percent: true});
            return {percent: true};
        }
    } else if (threshold.hasError('percent')) {
        threshold.updateValueAndValidity();
    }
    return null;
}
