<div class="event-feed" [class.flex-fill]="!onPhone()">
    <div class="filter-bar">
        <mat-form-field class="filter-select" appearance="outline" [class.active]="isSearchTextActive()">
            <mat-label *ngIf="isSearchTextActive()">Search Events</mat-label>
            <input matInput placeholder="Search Events" [(ngModel)]="searchText" (keyup)="filterEvents()" />
            <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
        </mat-form-field>

        <eaglei-date-range [gap]="'5px'" (change)="changeDateRange($event)" [start]="startDate" [end]="endDate"> </eaglei-date-range>

        <mat-button-toggle-group name="status" [value]="sortDirection" class="text-list">
            <mat-button-toggle [value]="-1" (change)="sortEvents(-1)">NEWEST FIRST</mat-button-toggle>
            <mat-button-toggle [value]="1" (change)="sortEvents(1)">OLDEST FIRST</mat-button-toggle>
        </mat-button-toggle-group>

        <button
            class="info-btn"
            mat-icon-button
            matTooltip="Where does the data come from?"
            matTooltipPosition="above"
            (click)="getSourceData()">
            <mat-icon fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
        </button>
    </div>

    <div class="chip-list">
        <span class="title">Filter Sources: </span>
        <mat-chip-listbox>
            <mat-chip-option *ngFor="let source of sources" [selected]="isSourceSelected(source)" (click)="toggleSourceSelected(source)">
                {{ source.source }}
            </mat-chip-option>
            <mat-chip (click)="activeSources = []; filterEvents()"> Clear All</mat-chip>
        </mat-chip-listbox>
    </div>

    <div class="event-list">
        <ng-container *ngIf="activeEvents.length !== 0; else noDataMask">
            <div *ngFor="let event of getPaginatorValues()" class="event" (click)="selectEvent(event)">
                <div [class.selected]="event === selectedEvent">
                    <p class="text-color event-title">{{ event.title }}</p>

                    <div class="event-info">
                        <div>
                            <span>Date </span> <b>{{ event.newsDate | momentDate }} </b>
                        </div>
                        <div *ngFor="let info of event.headerData">
                            <span>{{ info.name }} </span> <b>{{ info.display }} </b>
                        </div>
                    </div>

                    <div class="tags">
                        <p *ngFor="let keyword of event.keywords">{{ keyword.keyword.toUpperCase() }}</p>
                    </div>
                </div>

                <div class="event-content" [class.show-content]="selectedEvent === event">
                    <div class="message-content" [innerHtml]="event.content"></div>

                    <ng-container *ngIf="event.media.length > 0">
                        <div class="additional-info sub-title">Associated Media</div>
                        <div *ngFor="let media of event.media" class="media-list">
                            <a [href]="media.link" target="_blank" (click)="$event.stopPropagation()">{{ media.source }}</a>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="event.bodyData.length > 0">
                        <div class="additional-info sub-title">Additional Information</div>
                        <div *ngFor="let infoElement of event.bodyData">
                            <span class="message-content" *ngIf="event.source === 1" [innerHTML]="infoElement.display"></span>
                            <span *ngIf="event.source !== 1">{{ infoElement.name }}: {{ infoElement.display }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-template #noDataMask>
            <div class="auto-mask">
                <span class="mask-message text-color">{{ getMaskText() }}</span>
            </div>
        </ng-template>
    </div>

    <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
</div>
