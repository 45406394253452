import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MomentDatePipe} from '../../../../../shared/pipes/moment-date.pipe';
import {Subscription} from 'rxjs';
import {CoordinateFeature} from '../../classes/coordinate-feature';
import {ApplicationConfig} from '../../../../classes/application-config';
import {throttleTime} from 'rxjs/operators';
import * as moment from 'moment';
import {FileDownload} from '../../../../classes/file-download';
import * as JSZip from 'jszip';
import {PopoverElement} from '../../../../classes/popover-element';
import {ModalConfig} from '../../../../classes/modal-config';
import {LocationSearchService} from '../../services/location-search.service';
import {AppEnvironment} from '../../../../enums/environment.enum';

@Component({
    selector: 'eaglei-coordinate-data-modal',
    templateUrl: './coordinate-data-modal.component.html',
    styleUrls: ['./coordinate-data-modal.component.scss'],
})
export class CoordinateDataModalComponent implements AfterViewInit {
    get allDataLoaded(): boolean {
        return this._allDataLoaded;
    }

    @ViewChild('content') content: ElementRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    private momentPipe: MomentDatePipe = new MomentDatePipe();
    private sub: Subscription;

    public dataSource: MatTableDataSource<CoordinateFeature>;
    public displayColumns: string[] = ['arrow', 'name'];
    public expanded: CoordinateFeature;
    public initialLoad = false;
    public showMask = true;
    public maskText = 'Loading...';
    public readonly headerText: string;

    private _allDataLoaded: boolean;

    constructor(
        public dialogRef: MatDialogRef<CoordinateDataModalComponent>,
        public locationSearchService: LocationSearchService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.initialLoad = data.initialLoad || false;
        this.headerText = data.headerText || 'Data for Selected Coordinate';

        this.sub = new Subscription();
    }

    // Lifecycle events

    ngAfterViewInit() {
        this.initializeDataSource([]);
        this.setModalHeight();

        const dataSub = this.locationSearchService.intersectingFeatures.subscribe((features) => {
            this._allDataLoaded = !this.locationSearchService.loading;
            this.dataSource.data = features;
            this.isDataAvailable();
        });
        this.sub.add(dataSub);

        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => {
            this.setModalHeight();
        });
    }

    // Utility Methods
    private setModalHeight() {
        (this.content.nativeElement as HTMLElement).style.maxHeight = ModalConfig.getMaxModalHeight() + 'px';
    }

    private isDataSourceActive(): boolean {
        return this.dataSource && this.dataSource.data && this.dataSource.data.length === 0;
    }

    public closeModal(): void {
        this.sub.unsubscribe();
        this.dialogRef.close();
    }

    // Table Methods
    private initializeDataSource(data: CoordinateFeature[]): void {
        this.dataSource = new MatTableDataSource<CoordinateFeature>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSize = 10;
    }

    public toggleExpansion(row: CoordinateFeature): void {
        this.expanded = this.expanded === row ? (undefined as any) : row;
    }

    public isDataAvailable() {
        this.showMask = this.isDataSourceActive();
        if (this.showMask) {
            this.maskText = this.allDataLoaded ? 'No Data Found.' : 'Loading...';
        }
    }

    // Export Methods
    public exportData(): void {
        const filename = 'coordinateNavigation';
        const format = (s: string): string => {
            return s ? `"${s.toString().replace(/"/g, '""')}"` : 'Not Available';
        };

        let data: string = ['Layer', 'Name', 'Property', 'Value'].join() + '\n';
        this.dataSource.data.forEach((f) => {
            data += [format(f.layerName), format(f.name)].join() + '\n';

            f.data
                .filter((pe) => !pe.hideInLocationSearch && !pe.isDownloadLink)
                .forEach((pe: PopoverElement) => {
                    data += ['', '', format(pe.label), format(pe.value).trim()].join() + '\n';
                });
            data += '\n\n';
        });

        FileDownload.downloadCSV(filename, data);
    }

    public exportZipFile(): void {
        const env = (window as any).eaglei.env;

        // Zip File
        const zip = new JSZip();

        // Sets the last modified date to be zipDate converted to local time
        const zipDate = moment();
        const currDate = zipDate.local().toDate();
        JSZip.defaults.date = new Date(currDate.getTime() - currDate.getTimezoneOffset() * 60_000);

        let zipFileName = `coordinateNavigation_${zipDate.format('YYYYMMDDhhmmss')}`;
        const dir = zip.folder(zipFileName);

        const getExportColumns = (d: PopoverElement[]): PopoverElement[] => {
            return d.filter((ele) => ele.label.toLowerCase() !== 'title');
        };

        const m = new Map<string, CoordinateFeature[]>();
        this.dataSource.data.forEach((f) => {
            let val = m.get(f.layerName);
            if (val === undefined) {
                val = [];
            }
            val.push(f);
            m.set(f.layerName, val);
        });

        let data = '';

        m.forEach((value, key) => {
            const columnNames = value[0].data.filter((ele) => !ele.hideInLocationSearch && !ele.isDownloadLink).map((ele) => ele.label);

            data = ['Name', ...columnNames, '\n'].join();

            data += value
                .map((f) => {
                    const values = getExportColumns(f.data)
                        .filter((ele) => !ele.hideInLocationSearch && !ele.isDownloadLink)
                        .map((ele) => FileDownload.formatCsvCell(ele.value).trim());

                    return [FileDownload.formatCsvCell(f.name), ...values].join();
                })
                .join('\n');
            data += `\nReport Created At ${this.momentPipe.transform(moment())}`;

            let fileName = `${key
                .toLowerCase()
                .replace(/ /g, '_')
                .replace(/\//g, '_')
                .replace(/_layer/g, '')}.csv`;

            if (env !== AppEnvironment.PRODUCTION) {
                fileName = `${env}_${fileName}`;
            }

            dir.file(fileName, data);
        });

        if (env !== AppEnvironment.PRODUCTION) {
            zipFileName = `${env}_${zipFileName}`;
        }

        zip.generateAsync({type: 'blob'}).then((blob) => {
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, `${zipFileName}.zip`);
            } else {
                const url = window.URL.createObjectURL(blob);
                FileDownload.downloadFile(`${zipFileName}.zip`, url);
            }
        });
    }
}
