import {Injectable} from '@angular/core';
import {scaleLinear} from 'd3-scale';
import {ApplicationConfig} from '../../../classes/application-config';
import {LegendMetric} from '../../../../shared/enums/legend-metric.enum';
import {HistoricalOutageData} from '../classes/historical-outage-data';

interface LegendInfo {
    display: string;
    compareValue: number;
}

@Injectable({
    providedIn: 'root',
})
export class LayerStyleService {
    constructor() {
        this.voltageScale = scaleLinear().domain([0, 1000000]).range([1, 5]);

        this.percentOutDomain.push({value: 2, color: this.colorByPercent(2)});

        for (let i = 20; i <= 100; i += 10) {
            const item = {value: i, color: this.colorByPercent(i)};
            this.percentOutDomain.push(item);
        }
    }

    static layerStyles: Map<string, any> = new Map<string, any>();

    static nomRange = ['#7bf816', '#cee915', '#f8f016', '#f5852a', '#ef271d'];

    static readonly nomOutageLegend: LegendInfo[] = [
        {display: '1k', compareValue: 1000},
        {display: '10k', compareValue: 10000},
        {display: '50k', compareValue: 50000},
        {display: '200k', compareValue: 200000},
        {display: '>200k', compareValue: 200000},
    ];

    static readonly nomPercentLegend: LegendInfo[] = [
        {display: '2%', compareValue: 0.02},
        {display: '5%', compareValue: 0.05},
        {display: '10%', compareValue: 0.1},
        {display: '20%', compareValue: 0.2},
        {display: '>20%', compareValue: 0.2},
    ];

    // TODO: Refactor
    static colorOutage(outageData: HistoricalOutageData): string {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        const value = preferences.getLegendMetric() === LegendMetric.CUSTOMERS_OUT ? outageData.currentOutage : outageData.percentOut;

        // If there are no covered customers and no active Overrides. We always set the color to Grey.
        // This catches the case where there are overrides in a non covered county, because we still want to color
        if (!outageData.coveredCustomers && !value) {
            return LayerStyleService.colorByCount(-1);
        }

        // setting the color to grey anytime covered customers is 0 or undefined
        const invalidPercent = !outageData.currentOutageHasOverrideData && !outageData.coveredCustomers;

        // If we are on Percent legend, we need to check that we have a valid percent, if not return grey
        if (preferences.getLegendMetric() === LegendMetric.PERCENTAGE && invalidPercent) {
            return LayerStyleService.colorByCount(-1);
        }

        return LayerStyleService.colorByCount(value);
    }

    // TODO: Refactor
    static colorByCount(value: number | string, colorRange?: string[]): string {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        const metric = LegendMetric.from(preferences.legendMetric);
        const legendPreference = preferences.legendStyle;

        const legend = metric === LegendMetric.CUSTOMERS_OUT ? LayerStyleService.nomOutageLegend : LayerStyleService.nomPercentLegend;
        const values = legend.map((v) => v.compareValue);
        let range = LayerStyleService.nomRange;
        // debugger;

        if (legendPreference && legendPreference.colors) {
            range = legendPreference.colors;
        }

        if (colorRange !== undefined) {
            range = colorRange;
        }

        if (+value < 0) {
            return '#808080';
        } else if (+value <= values[0]) {
            return range[0];
        } else if (+value <= values[1]) {
            return range[1];
        } else if (+value <= values[2]) {
            return range[2];
        } else if (+value <= values[3]) {
            return range[3];
        } else if (+value > values[4]) {
            return range[4];
        } else {
            return '#808080';
        }
    }

    public voltageScale: any;

    public readonly percentOutDomain: any[] = [];

    public colorScale(value: number) {
        if (value <= 2) {
            return '#0a994d';
        } else if (value <= 20) {
            return '#a5db63';
        } else if (value <= 30) {
            return '#ffffe5';
        } else if (value <= 40) {
            return '#fff7bc';
        } else if (value <= 50) {
            return '#fee391';
        } else if (value <= 60) {
            return '#fec44f';
        } else if (value <= 70) {
            return '#fe9929';
        } else if (value <= 80) {
            return '#f66d3b';
        } else if (value <= 90) {
            return '#d92e1d';
        } else if (value >= 100) {
            return '#b11506';
        } else {
            return '#90230b';
        }
    }

    public colorByPercent(percent: number): string {
        let color: string;
        if (percent > 100) {
            percent = 100;
        }

        color = percent >= 0 ? this.colorScale(Number(percent)) : 'rgba(0,0,0,.5)';

        return color;
    }
}
