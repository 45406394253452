<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <mat-form-field appearance="outline" class="wide">
        <mat-label>Data Year</mat-label>
        <mat-select placeholder="Data Year" [formControl]="yearControl">
            <mat-option *ngFor="let server of serverOptions$ | async" [value]="server">{{ server }}</mat-option>
        </mat-select>
    </mat-form-field>

    <div #legendElement>
        <div class="dot-legend">
            <div class="circle"></div>
            <p>Survey Location</p>
        </div>
    </div>
</ng-container>
