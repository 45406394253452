<div class="hide-overflow">
    <div #header class="widget-header">
        <div class="title">
            <p>Port Status</p>
        </div>
        <div class="btn-container">
            <button mat-stroked-button (click)="goToReport()">
                <mat-icon class="icon" fontSet="fas" fontIcon="fa-file-alt" inline></mat-icon>
            </button>
        </div>
    </div>

    <div #content class="widget-content">
        <div *ngFor="let port of showingPorts" class="port">
            <div class="port-icon" [ngClass]="getPortStatus(port).toLowerCase()" [matTooltip]="getPortStatus(port) + ' Port'"></div>
            <p class="ellipsis">{{ port.name }}</p>
        </div>
    </div>

    <div #footer class="widget-footer">
        <mat-paginator hidePageSize showFirstLastButtons (page)="pagePorts($event)"></mat-paginator>
    </div>
</div>
