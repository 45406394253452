/* tslint:disable:quotemark */
import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Report} from '../../classes/report';
import * as moment from 'moment';
import {MaxUtility} from '../../classes/max-utility';
import {ChartDataService} from '../../../../../shared/services/chart-data.service';
import {ReportService} from '../../services/report.service';
import {DataService} from '../../../../services/data.service';
import {PopoverElement} from '../../../../classes/popover-element';
import {UtilityOutage} from '../../classes/utility-outage';
import {ApplicationConfig} from '../../../../classes/application-config';
import {State} from '../../../outage/classes/state';
import {ChartBounds} from '../../../../classes/chart-bounds';
import {FileDownload} from '../../../../classes/file-download';
import {OutageChartDataModalComponent} from '../../../../../shared/modals/outage-chart-data-modal/outage-chart-data-modal.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ColumnDef} from '../../../../../shared/classes/column-def';
import {ModalConfig} from '../../../../classes/modal-config';
import {MatTooltip} from '@angular/material/tooltip';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {ReportRequest} from '../../../../../shared/classes/report-request';
import {ReportRequestParameter} from '../../../../../shared/classes/report-request-parameter';
import {ReportParameter} from '../../../../../shared/enums/report-parmeter.enum';
import {EmailReportDataModalComponent} from '../../../../../shared/modals/email-report-data-modal/email-report-data-modal.component';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {LoadingMaskOptions} from '../../../../classes/loading-mask-options';
import {EagleiBaseChart} from '../../../../classes/charts/base-chart';
import {DataPoint} from '../../../../classes/data-point';
import {NumeralPipe} from 'frontend/src/shared/pipes/numeral.pipe';

@Component({
    selector: 'eaglei-ecoot-report',
    templateUrl: './ecoot-report.component.html',
    styleUrls: ['../reports.scss', './ecoot-report.component.scss'],
})
export class EcootReportComponent extends Report<MaxUtility> implements OnInit, AfterViewInit {
    get selectedAggregationLevel(): GenOutageAggregationLevel {
        return this._selectedAggregationLevel;
    }

    set selectedAggregationLevel(value: GenOutageAggregationLevel) {
        this._selectedAggregationLevel = value;
        this.columnNames = value === GenOutageAggregationLevel.state ? this.stateColumnNames : this.countyColumnNames;
    }

    get overrideColor(): string {
        return this._overrideColor;
    }

    private _overrideColor: string;

    public selectedStates: State[] = [];
    private _selectedAggregationLevel;
    private initialLoad = true;

    private chartResolution: 'minutes' | 'hours' | 'days';

    public maskOptions: LoadingMaskOptions = new LoadingMaskOptions();

    public showOverrideColor: boolean;

    public resetSelectedUtilties: boolean = true;

    // HTML Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('chartTarget') chartTarget: ElementRef<HTMLDivElement>;

    // Tooltips
    @ViewChild('currentTooltip') currentTooltip: MatTooltip;
    @ViewChild('hourTooltip') hourTooltip: MatTooltip;
    @ViewChild('dayTooltip') dayTooltip: MatTooltip;

    private callCount: number = 0;

    // Table Properties
    public columnNames = [];
    public readonly stateColumnNames = [
        'active',
        'name',
        'utilityId',
        'state',
        'region',
        'customersOut',
        'percentOut',
        'totalCustomers',
        'lastHourOutage',
        'lastDayOutage',
    ];
    public readonly countyColumnNames = [
        'active',
        'name',
        'utilityId',
        'county',
        'state',
        'region',
        'customersOut',
        'percentOut',
        'totalCustomers',
        'lastHourOutage',
        'lastDayOutage',
    ];
    private selectedUtilities: MaxUtility[] = [];

    // Chart Properties
    private chartBounds: ChartBounds<DataPoint, UtilityOutage>;

    public baseChart = new EagleiBaseChart();
    public chartUtilities: UtilityOutage[] = [];

    private renderTimeoutHandle;

    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter(
        (l) => ![GenOutageAggregationLevel.utility, GenOutageAggregationLevel.fema, GenOutageAggregationLevel.zip].includes(l)
    );

    // Chart Filter Properties
    public selectedDayBack: number = 1;
    public readonly daysBack = [
        {display: '1D', value: 1},
        {display: '2D', value: 2},
        {display: '3D', value: 3},
        {display: '4D', value: 4},
        {display: '5D', value: 5},
        {display: '6D', value: 6},
        {display: '7D', value: 7},
    ];

    // Filter Properties
    public endDate: moment.Moment = ApplicationConfig.roundMinute();
    public minDate: moment.Moment = this.endDate.clone().subtract(7, 'days');

    constructor(
        private dataService: DataService,
        public reportService: ReportService,
        private ele: ElementRef<HTMLElement>,
        private chartService: ChartDataService,
        private dialog: MatDialog
    ) {
        super(undefined, true);
        this.maskOptions.displayMask();

        this.getInitialPreferences();
        this.showOverrideColor = ApplicationConfig.showOverrideColor.getValue();

        this.ele.nativeElement.addEventListener('touchmove', () => this.baseChart.hidePopover());
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
        this.getReportData();
    }

    ngAfterViewInit() {
        this.initializeChart();

        ['scroll', 'touchmove', 'wheel'].forEach((type) => {
            this.ele.nativeElement.addEventListener(type, () => {
                this.currentTooltip?.hide();
                this.dayTooltip?.hide();
                this.hourTooltip?.hide();
            });
        });
    }

    onApplicationTimeChange() {
        this.endDate = ApplicationConfig.roundMinute();
        this.minDate = this.endDate.clone().subtract(7, 'days');

        this.getReportData();
    }

    // API Calls
    private getReportData() {
        this.callCount = 0;
        this.maskOptions.displayMask(LoadingMaskOptions.LOADING_TEXT);
        this.getTableUtilities();
        this.getChartUtilities();
    }

    /**
     * Gets all the utilities for the table with data in within 24 hours of the endDate
     */
    private getTableUtilities(): void {
        const key = 'ucootTable';
        HttpInterceptorService.clearInterceptor(key);
        HttpInterceptorService.pendingRequests[key] = this.reportService
            .getTableUtilities(this.endDate, this._selectedAggregationLevel)
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(key);
                this.initializeDataSource(res);

                this.callCount++;
                if (this.callCount === 2) {
                    this.joinApiCalls();
                }
            });
    }

    /**
     * Getting the utilities that will drive the chart
     * @param forceRedraw If set to true the chart with redraw as soon as the api returns, this should only be set when
     * it is known that the table data is loaded.
     */
    private getChartUtilities(forceRedraw: boolean = false): void {
        const key = 'ucootChart';
        const startDate = this.endDate.clone().subtract(this.selectedDayBack, 'days').startOf('day');

        // Adding this in for 7.5 release, During the release we updated `utilityoutagedata` to not round down to the last
        // completed runtime, subtracting the time here fixes the issue until we can update the outage pacakge with the
        // correct logic - RM
        const endDate = this.endDate.clone().subtract(15, 'minutes');

        HttpInterceptorService.clearInterceptor(key);
        HttpInterceptorService.pendingRequests[key] = this.reportService
            .getChartUtilities(startDate, endDate, this._selectedAggregationLevel)
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(key);
                this.chartResolution = res.resolution.toLowerCase() as any;
                this.chartUtilities = res.data.map((u) => new UtilityOutage(u));

                this.callCount++;

                if (this.callCount === 2 || forceRedraw) {
                    this.joinApiCalls();
                }
            });
    }

    /**
     * Waits until both APIs have finished to render the chart.
     */
    private joinApiCalls(): void {
        this.callCount = 0;
        this.initialLoad = false;
        this.filterData(this.dataSource.filter, true);
    }

    // Table Methods
    /**
     * Sets up the table interactions and data
     * @param data The utilities that will be in the table.
     */
    private initializeDataSource(data: MaxUtility[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<MaxUtility>(data);
            this.dataSource.sortingDataAccessor = this.sortAccessor;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }

        this.filterData(this.dataSource.filter);
    }

    /**
     * The logic behind the table filtering
     * @param data The Utility being checked
     * @param text the texted being searched for
     */
    private filterPredicate(data: MaxUtility, text: string): boolean {
        const typeCheck = this.selectedStates.findIndex((state) => data.state.id === state.id) !== -1;
        const nameCheck = data.utilityName.toLowerCase().includes(text.toLowerCase().trim());
        let acronymCheck = false;
        if (data.utilityAcronym) {
            acronymCheck = data.utilityAcronym.toLowerCase().includes(text.toLowerCase().trim());
        }
        return (nameCheck || acronymCheck) && typeCheck;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * The logic controlling the sorting on the table
     * @param data The utility being sorted
     * @param header The column header being sorted
     */
    private sortAccessor(data: MaxUtility, header: string): string | number {
        switch (header) {
            case 'name':
                return data.utilityName.toLowerCase();
            case 'utilityId':
                return data.utilityId;
            case 'state':
                return data.state.name;
            case 'county':
                return data.countyName.toLowerCase();
            case 'region':
                return data.region.id;
            case 'customersOut':
                return data.currentOutage;
            case 'percentOut':
                return data.percentOut;
            case 'totalCustomers':
                return data.coveredCustomers;
            case 'lastHourOutage':
                return data.maxOutage1;
            case 'lastDayOutage':
                return data.maxOutage24;
            case 'lastReport':
                return data.lastReportDateValue;
            case 'lastHourDate':
                return data.lastHourMaxDateValue;
            case 'lastDayDate':
                return data.lastDayMaxDateValue;
            default:
                return '';
        }
    }

    // Checkbox Methods
    /**
     * Checks to see if all the table utilities are in the selectedUtility list
     */
    public areAllChecked(): boolean {
        if (this.dataSource && this.dataSource.filteredData) {
            return this.selectedUtilities.length === this.dataSource.filteredData.length;
        }
        return false;
    }

    /**
     * adds or removes all utilities to the selectedUtility list
     * @param checked if true, add all, else remove
     */
    public toggleAllUtilities(checked: boolean): void {
        this.maskOptions.displayMask(LoadingMaskOptions.LOADING_TEXT);
        this.selectedUtilities = checked ? this.dataSource.filteredData.slice() : [];
        this.renderChart();
    }

    /**
     * Returns true if only a subset of the table utilites are in the selectedUtility list
     */
    public isIndeterminate(): boolean {
        if (this.dataSource && this.dataSource.filteredData) {
            const length = this.selectedUtilities.length || 0;
            return !(length === 0 || length === this.dataSource.filteredData.length);
        }
        return false;
    }

    /**
     * Adds or removed a utility from the Selected Utility list
     * @param utility the utility being updated
     * @param checked if true, add the utility, else remove
     */
    public toggleUtility(utility: MaxUtility, checked: boolean): void {
        const index = this.selectedUtilities.findIndex((u) => u.reportUtilityId === utility.reportUtilityId);

        if (index === -1 && checked) {
            this.selectedUtilities.push(utility);
        } else if (index !== -1 && !checked) {
            this.selectedUtilities.splice(index, 1);
        }

        this.renderChart();
    }

    /**
     * Returns if a utility is in the selectedUtility list
     * @param utility The utility being searched for.
     */
    public isUtilitySelected(utility: MaxUtility): boolean {
        return this.selectedUtilities.findIndex((u) => u.reportUtilityId === utility.reportUtilityId) !== -1;
    }

    // Chart Methods
    /**
     * Initializes the chart with a skeleton config and sets up interactions.
     */
    private initializeChart(): void {
        this.baseChart.interactivePopover = true;
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Customers Without Power');

        this.baseChart.eChart.on('finished', () => {
            if (this.initialLoad) {
                return;
            }

            if (this.selectedUtilities.length === 0) {
                this.maskOptions.displayMask('No Data Selected');
                return;
            }

            if (!this.baseChart.hasData()) {
                this.maskOptions.displayMask('No Data Available');
                return;
            }

            this.maskOptions.resetMask();
        });

        const xAxisFormat = (date) => {
            const value = parseInt(date);
            let format = 'MM/DD h:mm A';

            if (this.chartResolution === 'days') {
                format = 'MM/DD';
            }

            return moment(value).format(format);
        };

        (this.baseChart.eChartOptions.xAxis as echarts.EChartOption.XAxis).axisLabel.formatter = xAxisFormat.bind(this);

        this.baseChart.setOptions();
    }

    /**
     * Filters down the chart data to only what is in the table and then summarizes that data for the bar chart.
     */
    private summarizeChartData(): ChartBounds<any> {
        const getKey = (u: UtilityOutage) => (this.chartResolution === 'days' ? u.timestampValue : u.timestampValue);

        const createDataPoint = (u: UtilityOutage) => {
            const d = {
                x: this.chartResolution === 'days' ? u.timestampValue : u.timestampValue,
                y: 0,
                timeStamp: this.chartResolution === 'days' ? u.timeStamp : u.timeStamp,
                hasOverride: u.hasOverrideData,
                id: u.reportUtilityId,
            };
            return new DataPoint(d);
        };
        const updateDataPoint = (dp: DataPoint, utility: UtilityOutage) => {
            dp.data.y += utility.customersOut || utility.maxCustomersOut || 0;
            dp.data.hasOverride = dp.data.hasOverride || utility.hasOverrideData;
        };

        // If it is not checked in the table it will not be in the chart.
        const data = this.chartUtilities.filter((cu) => {
            return this.selectedUtilities.findIndex((su) => su.reportUtilityId === cu.reportUtilityId) !== -1;
        });

        this.chartBounds = new ChartBounds<DataPoint, UtilityOutage>();
        let resolutionInMs: number;

        if (this.chartResolution === 'days') {
            resolutionInMs = 1000 * 60 * 60 * 24;
        } else if (this.chartResolution === 'hours') {
            resolutionInMs = 1000 * 60 * 60;
        }

        this.chartBounds.rawData = data;
        this.chartBounds.data = this.chartService.getTimeBars(data, getKey, createDataPoint, updateDataPoint, resolutionInMs);

        return this.chartBounds;
    }

    /**
     * processes the filtered data into a format the chart can injest and renders the chart.
     */
    private renderChart(): void {
        this.summarizeChartData();
        this.maskOptions.displayMask();

        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        const ids = this.selectedUtilities.map((su) => su.reportUtilityId);

        // Using A set since the id for the map is a concatenated string there could be multiple instances of the same date.
        const xValues = Array.from(new Set(this.chartBounds.data.map((dp) => moment(dp.data.x).valueOf())));
        const yValues = this.chartBounds.data
            .filter((dp) => dp.data.generatedData || ids.includes(dp.data.id))
            .sort((a, b) => (a.data.x > b.data.x ? 1 : -1))
            .map((dp) => {
                const barColor =
                    dp.data.hasOverride && preferences.hasLegendStyle() && this.showOverrideColor
                        ? preferences.legendStyle.overrideColor
                        : ApplicationConfig.chartLineColor;

                const onBarClick = () => {
                    this.openDataModal(dp.data.x);
                };

                return {
                    value: dp.data.y,
                    itemStyle: {color: barColor},
                    popoverData: [
                        new PopoverElement('Title', Report.momentPipe.transform(dp.data.x)).setTitle(),
                        new PopoverElement('Customers Out', Report.numberPipe.transform(dp.data.y).toString()),
                        new PopoverElement('View Data', dp.data.x, false, true).setOnClick(onBarClick),
                    ],
                    exportData: {
                        yValue: dp.data.y,
                        xValue: moment(dp.data.x).format(),
                    },
                };
            });

        this.baseChart.eChartOptions.xAxis['data'] = xValues;
        this.baseChart.eChartOptions.xAxis['name'] = this.getSubtext();

        this.baseChart.eChartOptions.series = [
            {
                type: 'bar',
                data: yValues,
            },
        ];

        this.baseChart.setOptions();
    }

    /**
     * Returns the label for the x-axis
     */
    private getSubtext(): string {
        let interval = '15 minute';
        if (this.chartResolution === 'hours') {
            interval = '1 hour';
        } else if (this.chartResolution === 'days') {
            interval = '1 day';
        }
        return `Outages reported in ${interval} intervals`;
    }

    // Filter Methods
    /**
     * Sets the text to search for in Utility names
     * @param searchText The utility name being searched for.
     * @param renderChart Optional parameter when set to true, will re-render the chart, default is false
     */
    public filterData(searchText: string, renderChart: boolean = false): void {
        if (this.renderTimeoutHandle) {
            clearTimeout(this.renderTimeoutHandle);
            this.renderTimeoutHandle = undefined;
        }

        this.renderTimeoutHandle = setTimeout(() => {
            searchText = searchText === '' ? ' ' : searchText;
            this.dataSource.filter = searchText;
            if (this.resetSelectedUtilties) {
                this.selectedUtilities = this.dataSource.filteredData.slice();
            }
            this.resetSelectedUtilties = true;

            if (renderChart) {
                this.renderChart();
            }
        }, 250);
    }

    /**
     * Changes the states that are selected in the table and chart.
     * @param states The list of states to be shown in the table
     */
    public changeSelectedLocations(states: State[]): void {
        this.maskOptions.displayMask(LoadingMaskOptions.LOADING_TEXT);
        this.selectedStates = states.slice();
        this.filterData(this.dataSource.filter, true);

        if (this.selectedStates.length === 0) {
            this.maskOptions.displayMask('No States Selected');
        }
    }

    /**
     * Updates the aggregation level the table is being viewed at, this changes causes the APIS to refresh and the data to be reloaded.
     * @param event The event from the material button group
     */
    public changeAggregation(event: MatButtonToggleChange) {
        this.selectedAggregationLevel = event.value;
        this.getReportData();
    }

    /**
     * Returns true if the text search has a value in it, false otherwise
     */
    public isSearchTextActive(): boolean {
        return this.dataSource && this.dataSource.filter && this.dataSource.filter.trim().length > 0;
    }

    /**
     * changes the end date for the table so all utility data will be based on 24 hours previous to the end date.
     * @param date The value the end date is set to.
     */
    public updateEndDate(date: moment.Moment): void {
        this.endDate = date;
        this.getReportData();
    }

    // Export Methods
    /**
     * Exports the table as a CSV.
     */
    public exportTable(): void {
        const femaPipe = new NumeralPipe();

        const columns: string[] = [
            'Utility Name',
            'EAGLE-I (TM) ID',
            'State/Territory',
            'FEMA Region',
            'Customers Out',
            'Percent Out',
            'Total Customers',
            'Last Hour Max Outage',
            'Last Day Max Outage',
            'Last Report Date',
            'Last Hour Max Date',
            'Last Day Max Date',
            'Total Customers Type',
            'Link',
        ];

        if (this._selectedAggregationLevel === GenOutageAggregationLevel.county) {
            columns.splice(2, 0, 'County');
        }

        let data = `${columns.join()}\n`;

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            let link = 'Not Available';
            if (val.url) {
                link = `"=HYPERLINK("${FileDownload.formatCsvCell(val.url)}","${FileDownload.formatCsvCell(val.url)}")"`;
            }

            const row = [
                FileDownload.formatCsvCell(val.utilityName),
                FileDownload.formatCsvCell(val.utilityId),
                FileDownload.formatCsvCell(val.stateName),
                FileDownload.formatCsvCell(femaPipe.transform(val.region.id.toString(), true)),
                FileDownload.formatCsvCell(Report.numberPipe.transform(val.currentOutage)),
                FileDownload.formatCsvCell(Report.numberPipe.transform(val.percentOut * 100, {specifier: ',.2f'})),
                FileDownload.formatCsvCell(Report.numberPipe.transform(val.coveredCustomers)),
                FileDownload.formatCsvCell(Report.numberPipe.transform(val.maxOutage1)),
                FileDownload.formatCsvCell(Report.numberPipe.transform(val.maxOutage24)),
                FileDownload.formatCsvCell(Report.momentPipe.transform(val.lastReportedOutageRunStartTime)),
                FileDownload.formatCsvCell(Report.momentPipe.transform(val.maxOutage1RunStartTime)),
                FileDownload.formatCsvCell(Report.momentPipe.transform(val.maxOutage24RunStartTime)),
                FileDownload.formatCsvCell(val.coveredCustomersType),
                link,
            ];

            if (this._selectedAggregationLevel === GenOutageAggregationLevel.county) {
                row.splice(2, 0, FileDownload.formatCsvCell(val.countyName));
            }

            data += `${row.join()}\n`;
        });

        data += this.getSubtext() + '\n';
        FileDownload.downloadCSV('electricCustomersOutOverTime', data, this.attributionUrl);
    }

    /**
     * Exports the chart as a CSV
     */
    public exportChartAsCsv(): void {
        let data = '';
        const columns = ['Date', 'Customers Without Power'];

        data += columns.join() + '\n';

        this.baseChart.eChart.getOption().series.forEach((series) => {
            series.data.forEach((sd) => {
                data += [FileDownload.formatCsvCell(sd.exportData.xValue), FileDownload.formatCsvCell(sd.exportData.yValue)].join() + '\n';
            });
        });

        data += this.getSubtext() + '\n';
        FileDownload.downloadCSV('electricCustomersOutOverTimeTrend', data, this.attributionUrl);
    }

    /**
     * Export chart as PNG
     */
    public exportChart(): void {
        const start = this.endDate.clone().subtract(this.selectedDayBack, 'days').startOf('day');
        const title =
            `Electric customers out from ${Report.momentPipe.transform(start, '', true)}` +
            ` to ${Report.momentPipe.transform(this.endDate, '', true)}`;

        FileDownload.exportChartAsPNG(this.baseChart, title, title, this.attributionUrl);
    }

    // Helper Methods
    /**
     * sets up the initial state filters for the user
     */
    private getInitialPreferences(): void {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        this.selectedStates = preferences.getStates();
        this.selectedAggregationLevel = preferences.getOutageAggregationLevel();
        this._overrideColor = preferences.getOverrideColor();
    }

    /**
     * Changes the start date of the bar chart
     * @param daysBack The number of days back the chart start date should be
     */
    public updateChartStartDate(daysBack: number): void {
        this.selectedDayBack = daysBack;
        this.maskOptions.displayMask(LoadingMaskOptions.LOADING_TEXT);
        this.resetSelectedUtilties = false;
        this.getChartUtilities(true);
    }

    /**
     * Toggles if utilities with overrides are colored differently in the UI.
     */
    public toggleOverrideColor(): void {
        this.showOverrideColor = !this.showOverrideColor;
        this.renderChart();
    }

    /**
     * opens the detailed view modal for the chart.
     * @param xAxisValue The timesatamp of the bar clicked
     */
    public openDataModal(xAxisValue: string) {
        const isCounty = GenOutageAggregationLevel.county === this.selectedAggregationLevel;
        const ts = moment(parseInt(xAxisValue));
        const columnDefs: ColumnDef<UtilityOutage>[] = [
            new ColumnDef<UtilityOutage>('Utility Name', (u) => u.utilityName),
            new ColumnDef<UtilityOutage>('State/Territory', (u) => u.stateName).sort((u) => u.stateName),
            new ColumnDef<UtilityOutage>('Customers Out', (u) => Report.numberPipe.transform(u.maxCustomersOut || 0))
                .sort((u) => u.maxCustomersOut)
                .setDefaultSort(true, 'desc'),
        ];
        let ref: MatDialogRef<OutageChartDataModalComponent>;
        let modalData = [];

        if (!isCounty) {
            const searchText = this.dataSource.filter;
            modalData = this.chartBounds.rawData.filter((utility) => {
                const nameCheck = searchText ? utility.utilityName.toLowerCase().includes(searchText.toLowerCase().trim()) : true;
                const acronymCheck = searchText ? utility.utilityAcronym?.toLowerCase().includes(searchText.toLowerCase().trim()) : true;
                return utility.timeStamp.isSame(ts) && (nameCheck || acronymCheck);
            });
        } else {
            const selectedIds = this.selectedUtilities.map((u) => u.reportUtilityId);
            const startTime = ts.clone();
            let endTime = startTime.clone();

            switch (this.chartResolution) {
                case 'hours':
                    endTime = startTime.clone().add(45, 'minute');
                    break;
                case 'days':
                    endTime = startTime.clone().add(1, 'day');
                    break;
            }

            this.reportService.getChartUtilities(startTime, endTime, this._selectedAggregationLevel).subscribe((res) => {
                let data;

                if (this.chartResolution !== 'minutes') {
                    const countyMap = new Map<string, UtilityOutage>();

                    res.data.forEach((utility) => {
                        let value = countyMap.get(utility.reportUtilityId);

                        if (!value) {
                            value = utility;
                        }

                        value.maxCustomersOut = Math.max(value.maxCustomersOut, utility.maxCustomersOut);
                        countyMap.set(utility.reportUtilityId, value);
                    });

                    data = Array.from(countyMap.values());
                } else {
                    data = res.data;
                }

                const stateAbbv = this.selectedStates.map((s) => s.abbreviation);
                const searchText = this.dataSource?.filter?.toLowerCase().trim();
                const filtered = data.filter((utility) => {
                    const stateCheck = stateAbbv.includes(utility.stateAbbreviation);
                    const nameCheck = searchText ? utility.utilityName.toLowerCase().includes(searchText) : true;
                    const acronymCheck = searchText
                        ? utility.utilityAcronym?.toLowerCase().includes(searchText.toLowerCase().trim())
                        : true;
                    const idCheck = selectedIds.includes(utility.reportUtilityId);
                    return stateCheck && (nameCheck || acronymCheck) && idCheck;
                });
                ref.componentInstance.updateData(filtered);
            });

            const countyColumn = new ColumnDef<UtilityOutage>('County', (u) => u.countyName).sort((u) => u.countyName.toLowerCase());
            columnDefs.splice(1, 0, countyColumn);
        }

        const config: any = {
            width: ModalConfig.getModalWidth(),
            data: {
                timestamp: xAxisValue,
                tableData: modalData,
                title: `Utility Outages for ${Report.momentPipe.transform(ts)}`,
                columnDefs,
            },
        };

        // Object.assign(event.dialogConfig.data, config);
        ref = this.dialog.open(OutageChartDataModalComponent, config);
    }

    public openEmailModal(): void {
        const request = new ReportRequest();
        request.reportName = 'UCOOT Report';
        request.userDefinedReportId = 2;
        request.parameters = [
            new ReportRequestParameter().setName(ReportParameter.AGGREGATION_LEVEL).setValue(this._selectedAggregationLevel),
            new ReportRequestParameter().setName(ReportParameter.STATE_IDS).setValue(this.selectedStates.map((s) => s.id).join(',')),
            new ReportRequestParameter().setName(ReportParameter.TEXT_SEARCH).setValue(this.dataSource.filter),
        ];

        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
            data: {
                request,
            },
            disableClose: true,
        };

        this.dialog.open(EmailReportDataModalComponent, config);
    }
}
