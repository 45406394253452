import {Injectable} from '@angular/core';
import {RRCCLocation} from '../modules/management/classes/rrcc-location';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {RRCCDesk} from '../modules/management/classes/rrcc-desk';
import {RRCCDeployment} from '../modules/management/classes/rrcc-deployment';
import {RRCCPersonnel} from '../classes/rrcc-personnel';

@Injectable({
    providedIn: 'root',
})
export class RrccService {
    private readonly baseUrl = 'api/rrcc';

    constructor(private http: HttpClient) {}

    public updateLocationContact(location: RRCCLocation): Observable<RRCCLocation> {
        const url = `${this.baseUrl}/location-contact`;

        return this.http.put<any>(url, location).pipe(map((l) => new RRCCLocation(l)));
    }

    public getRRCCLocations(): Observable<RRCCLocation[]> {
        const url = `api/rrcc/locations`;
        return this.http.get<RRCCLocation[]>(url);
    }

    public updateRRCCLocation(location: RRCCLocation): Observable<any> {
        let url = `api/rrcc/location`;
        if (location.id !== undefined) {
            url += `/${location.id}`;
            return this.http.put(url, location);
        } else {
            return this.http.post(url, location);
        }
    }

    public deleteRRCCLocation(location: RRCCLocation): Observable<any> {
        const url = `api/rrcc/location/${location.id}`;

        return this.http.delete(url);
    }

    public removePersonnelFromLocation(locationId: number, personnelId: number): Observable<any> {
        const url = `api/rrcc/remove-personnel-location?locationId=${locationId}&personnelId=${personnelId}`;
        return this.http.get(url);
    }

    public removeContactFromLocation(locationId: number, contactId: number): Observable<any> {
        const url = `api/rrcc/remove-contact-location?locationId=${locationId}&contactId=${contactId}`;
        return this.http.get(url);
    }

    public addEventToLocation(locationId: number, eventId: number): Observable<void> {
        const url = `api/rrcc/event-location?eventId=${eventId}&locationId=${locationId}`;
        return this.http.post<void>(url, undefined);
    }

    public removeEventFromLocation(locationId: number, eventId: number): Observable<void> {
        const url = `api/rrcc/event-location?eventId=${eventId}&locationId=${locationId}`;
        return this.http.delete<void>(url);
    }

    public createDesk(desk: RRCCDesk): Observable<any> {
        const url = `api/rrcc/desk`;
        return this.http.post(url, desk);
    }

    public startDeployment(deploy: RRCCDeployment): Observable<any> {
        const url = `api/rrcc/start-deployment?personnelId=${deploy.personnelId}&locationId=${deploy.locationId}&shiftId=${
            deploy.shiftId
        }&startDate=${deploy.startTime.format()}&deskId=${null}`;
        return this.http.post(url, undefined);
    }

    public endDeployment(depoly: RRCCDeployment): Observable<any> {
        const url = `api/rrcc/end-deployment/${depoly.id}?endDate=${depoly.endTime.format()}`;
        return this.http.put(url, undefined);
    }

    public createPersonnel(personnel: RRCCPersonnel): Observable<any> {
        const url = `api/rrcc/personnel`;
        return this.http.post(url, personnel);
    }

    public getBattleRhythm(locations: RRCCLocation[]): Observable<any> {
        const url = `api/rrcc/battle-rhythm`;
        return this.http.post(url, locations, {responseType: 'blob'});
    }
}
