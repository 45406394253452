<div class="dialog-header">
    <span>Manage Templates</span>
    <mat-icon inline class="clickable" fontSet="fas" fontIcon="fa-times" (click)="closeModal()"> </mat-icon>
</div>

<div #content class="dialog-content">
    <p class="info-text">
        Access all your templates to rename, remove old or unwanted, or set a new default template by clicking the menu next to the
        corresponding template.
    </p>

    <div>
        <div *ngFor="let layout of layouts" class="layout">
            <ng-container *ngIf="!layout.editing; else editing">
                <div class="layout-name">
                    <span>{{ layout.name }}</span>
                    <div *ngIf="layout.defaultLayout" class="tag">
                        <p>Default</p>
                    </div>
                </div>
            </ng-container>

            <ng-template #editing>
                <div class="template-names">
                    <mat-form-field appearance="outline">
                        <input type="text" matInput [(ngModel)]="tmpName" />
                    </mat-form-field>
                    <mat-icon inline fontSet="fas" fontIcon="fa-check" class="clickable" (click)="saveLayoutName(layout)"></mat-icon>
                    <mat-icon inline fontSet="fas" fontIcon="fa-times" class="clickable" (click)="cancelRename(layout)"></mat-icon>
                </div>
            </ng-template>

            <div>
                <button mat-icon-button [matMenuTriggerFor]="layoutMenu">
                    <mat-icon fontSet="fas" fontIcon="fa-ellipsis-v"></mat-icon>
                </button>
                <mat-menu #layoutMenu="matMenu" overlapTrigger>
                    <button mat-menu-item (click)="rename(layout)">
                        <img class="resize-icon" src="/dist/images/icons/rename.svg" alt="Rename File" />
                        <span>Rename</span>
                    </button>
                    <button mat-menu-item (click)="queueToRemove(layout)">
                        <mat-icon fontSet="fas" fontIcon="fa-trash-alt"></mat-icon>
                        <span>Delete</span>
                    </button>
                    <button mat-menu-item (click)="setAsDefault(layout)">
                        <mat-icon fontSet="fas" fontIcon="fa-cog"></mat-icon>
                        <span>Set as Default</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>

<div class="dialog-actions">
    <button mat-stroked-button (click)="clearDefaults()" class="revert">Revert To System Default</button>

    <div>
        <!--    <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>-->
        <button mat-flat-button color="primary" (click)="closeModal()">Done</button>
    </div>
</div>
