<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend line-legend">
            <span class="label">Pipeline Capacity Utilization: </span>
            <div class="classification">
                <div class="flow-low"></div>
                <span class="sml-text"> High: &gt; 90%</span>
            </div>
            <div class="classification">
                <div class="flow-med"></div>
                <span class="sml-text"> Medium: 70% - 90%</span>
            </div>
            <div class="classification">
                <div class="flow-high"></div>
                <span class="sml-text"> Low: &lt; 70%</span>
            </div>
        </div>
    </div>
</ng-container>
