
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenUtilityUrl } from './GenUtilityUrl';
import * as moment from 'moment';

export class GenMax24HourUtility {

    actualCustomers: number;
    boundary: string;
    countyId: number;
    countyName: string;
    coveredCustomers: number;
    coveredCustomersType: string;
    currentOutage: number;
    currentOutageHasOverrideData: boolean;
    currentOutageRunStartTime: moment.Moment;
    eagleiId: string;
    femaRegion: string;
    lastReportedOutageRunStartTime: moment.Moment;
    maxOutage1: number;
    maxOutage1HasOverrideData: boolean;
    maxOutage1RunStartTime: moment.Moment;
    maxOutage24: number;
    maxOutage24HasOverrideData: boolean;
    maxOutage24RunStartTime: moment.Moment;
    nonCoveredCustomers: number;
    parentUtilityName: string;
    stateId: number;
    stateName: string;
    subUtilityId: number;
    subUtilityName: string;
    url: string;
    utilityAcronym: string;
    utilityId: number;
    utilityName: string;
    utilityNumber: number;
    utilityUrls: GenUtilityUrl[];

    constructor();
    constructor( jsonObj: any );
    constructor( genMax24HourUtility: GenMax24HourUtility);

    constructor( input?: GenMax24HourUtility ) {
        if ( input && typeof input !== 'number' ) {
            this.actualCustomers = input.actualCustomers; // int [number]
            this.boundary = input.boundary; // string [string]
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.coveredCustomersType = input.coveredCustomersType; // string [string]
            this.currentOutage = input.currentOutage; // int [number]
            this.currentOutageHasOverrideData = input.currentOutageHasOverrideData; // boolean [boolean]
            if ( input.currentOutageRunStartTime && typeof input.currentOutageRunStartTime === 'string' ) {
                this.currentOutageRunStartTime = moment(input.currentOutageRunStartTime).utc();
            } else {
                this.currentOutageRunStartTime = input.currentOutageRunStartTime;
            }
            this.eagleiId = input.eagleiId; // string [string]
            this.femaRegion = input.femaRegion; // string [string]
            if ( input.lastReportedOutageRunStartTime && typeof input.lastReportedOutageRunStartTime === 'string' ) {
                this.lastReportedOutageRunStartTime = moment(input.lastReportedOutageRunStartTime).utc();
            } else {
                this.lastReportedOutageRunStartTime = input.lastReportedOutageRunStartTime;
            }
            this.maxOutage1 = input.maxOutage1; // int [number]
            this.maxOutage1HasOverrideData = input.maxOutage1HasOverrideData; // boolean [boolean]
            if ( input.maxOutage1RunStartTime && typeof input.maxOutage1RunStartTime === 'string' ) {
                this.maxOutage1RunStartTime = moment(input.maxOutage1RunStartTime).utc();
            } else {
                this.maxOutage1RunStartTime = input.maxOutage1RunStartTime;
            }
            this.maxOutage24 = input.maxOutage24; // int [number]
            this.maxOutage24HasOverrideData = input.maxOutage24HasOverrideData; // boolean [boolean]
            if ( input.maxOutage24RunStartTime && typeof input.maxOutage24RunStartTime === 'string' ) {
                this.maxOutage24RunStartTime = moment(input.maxOutage24RunStartTime).utc();
            } else {
                this.maxOutage24RunStartTime = input.maxOutage24RunStartTime;
            }
            this.nonCoveredCustomers = input.nonCoveredCustomers; // int [number]
            this.parentUtilityName = input.parentUtilityName; // string [string]
            this.stateId = input.stateId; // int [number]
            this.stateName = input.stateName; // string [string]
            this.subUtilityId = input.subUtilityId; // int [number]
            this.subUtilityName = input.subUtilityName; // string [string]
            this.url = input.url; // string [string]
            this.utilityAcronym = input.utilityAcronym; // string [string]
            this.utilityId = input.utilityId; // int [number]
            this.utilityName = input.utilityName; // string [string]
            this.utilityNumber = input.utilityNumber; // int [number]
            if ( input.utilityUrls && input.utilityUrls.map ) { // list [GenUtilityUrl]
                this.utilityUrls = input.utilityUrls.map( ( x: GenUtilityUrl ) => {
                    return new GenUtilityUrl( x );
                } );
            } else {
                this.utilityUrls = input.utilityUrls;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenMax24HourUtility[] {
        if (arr && arr.map) {
          return arr.map(a => new GenMax24HourUtility(a));
        } else {
          return [];
        }
    }
}

