export class ColumnDef<D = any> {
    get subscript(): (val: D) => string {
        return this._subscript;
    }

    get cellClass(): string {
        return this._cellClass;
    }

    get cellColor(): (val: D) => string {
        return this._cellColor;
    }

    get sortDirection(): 'asc' | 'desc' {
        return this._sortDirection;
    }

    get defaultSort(): boolean {
        return this._defaultSort;
    }

    get sortValue(): (val: any) => string | number {
        return this._sortValue;
    }

    get hasTooltip(): boolean {
        return this._hasTooltip;
    }

    get columnName(): string {
        return this._columnName;
    }

    private readonly _columnName: string;
    private _hasTooltip: boolean;
    private _defaultSort: boolean;
    private _sortValue: (val: D) => string | number;
    private _sortDirection: 'asc' | 'desc';
    private _cellColor: (val: D) => string;
    private _cellClass: string;
    private _subscript: (val: D) => string;

    public displayName: string;
    public getValue: (val: D) => string | number;

    constructor(displayName: string, getValue: (val: D) => string | number) {
        this.displayName = displayName || '';
        this.getValue = getValue;
        this._columnName = this.displayName.replace(/ /g, '').toLowerCase();
    }

    public sort(s: (val: D) => string | number): ColumnDef<D> {
        this._sortValue = s;
        return this;
    }

    public setSubscript(s: (val: D) => string, className?: string): ColumnDef<D> {
        this._subscript = s;
        if (className) this._cellClass = className;

        return this;
    }

    public setDefaultSort(sort: boolean = true, sortDirection: 'asc' | 'desc' = 'desc'): ColumnDef<D> {
        this._defaultSort = sort;
        this._sortDirection = sortDirection;
        return this;
    }

    public setHasTooltip(hasTooltip: boolean = true): ColumnDef<D> {
        this._hasTooltip = hasTooltip;
        return this;
    }

    public setCellColor(cellColor: (val: D) => string): ColumnDef<D> {
        this._cellColor = cellColor;
        return this;
    }
}
