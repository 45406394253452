import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MomentDatePipe} from './pipes/moment-date.pipe';
import {EagleINumberPipe} from './pipes/eaglei-number.pipe';
import {NumeralPipe} from './pipes/numeral.pipe';
import {PhoneNumberPipe} from './pipes/phone-number.pipe';
import {SanitizePipe} from './pipes/sanitize.pipe';
import {RoleDefinitionPipe} from './pipes/role-definition.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../app/material.module';
import {DateRangeComponent} from './components/date-range/date-range.component';
import {DataInfoComponent} from './modals/data-info/data-info.component';
import {AutocompleteComponent} from './components/autocomplete/autocomplete.component';
import {ConfirmationComponent} from './modals/confirmation/confirmation.component';
import {DenyOptionsComponent} from './modals/deny-options/deny-options.component';
import {BulkEmailComponent} from './modals/bulk-email/bulk-email.component';
import {PopoverComponent} from './components/popover/popover.component';
import {SelectClearComponent} from './components/select-clear/select-clear.component';
import {ResizeComponent} from './components/resize/resize.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {TableSortHeaderComponent} from './components/table-sort-header/table-sort-header.component';
import {EocDatePickerComponent} from './components/eoc-date-picker/eoc-date-picker.component';
import {LocationFilterComponent} from './components/location-filter/location-filter.component';
import {AggregationLevelPipe} from './pipes/aggregation-level.pipe';
import {NomLegendComponent} from './components/nom-legend/nom-legend.component';
import {RegionFilterComponent} from './components/region-filter/region-filter.component';
import {LegendMetricPipe} from './pipes/legend-metric.pipe';
import {DatapointTypePipe} from './pipes/datapoint-type.pipe';
import {WidgetNamePipe} from './pipes/widget-name.pipe';
import {FilterChipListComponent} from './components/filter-chip-list/filter-chip-list.component';
import {OutageChartDataModalComponent} from './modals/outage-chart-data-modal/outage-chart-data-modal.component';
import {FileUploadModalComponent} from './modals/file-upload-modal/file-upload-modal.component';
import {ShortNumberPipe} from './pipes/short-number.pipe';
import {StateNamePipe} from './pipes/state-name.pipe';
import {EmailReportDataModalComponent} from './modals/email-report-data-modal/email-report-data-modal.component';
import {NumberInputDirective} from './directives/number-input.directive';
import {LocationChipFilterComponent} from './components/location-chip-filter/location-chip-filter.component';
import {ReplaceCharacterPipe} from './pipes/replace-character.pipe';
import {FileDragAndDropDirective} from './directives/file-drag-and-drop.directive';
import {TimeSliderComponent} from './components/time-slider/time-slider.component';
import {DateTimePickerComponent} from './components/date-time-picker/date-time-picker.component';
import {DashboardTypePipe} from './pipes/dashboard-type.pipe';
import {ArchiveRequestModalComponent} from './modals/archive-request-modal/archive-request-modal.component';
import {TrainingAccessRequestComponent} from './modals/training-access-request/training-access-request.component';
import {InfoModalComponent} from './modals/info-modal/info-modal.component';
import {InfoIconComponent} from './components/info-icon/info-icon.component';
import {SelectAllOptionDirective} from './components/select-all-option';

const components = [
    DateRangeComponent,
    DataInfoComponent,
    AutocompleteComponent,
    ConfirmationComponent,
    DenyOptionsComponent,
    BulkEmailComponent,
    PopoverComponent,
    SelectClearComponent,
    ResizeComponent,
    DataTableComponent,
    TableSortHeaderComponent,
    EocDatePickerComponent,
    LocationFilterComponent,
    AggregationLevelPipe,
    NomLegendComponent,
    RegionFilterComponent,
    FilterChipListComponent,
    OutageChartDataModalComponent,
    FileUploadModalComponent,
    EmailReportDataModalComponent,
    LocationChipFilterComponent,
    TimeSliderComponent,
];

const pipes = [
    MomentDatePipe,
    EagleINumberPipe,
    NumeralPipe,
    PhoneNumberPipe,
    SanitizePipe,
    RoleDefinitionPipe,
    LegendMetricPipe,
    DatapointTypePipe,
    WidgetNamePipe,
    ShortNumberPipe,
    StateNamePipe,
    ReplaceCharacterPipe,
];

const directives = [NumberInputDirective, FileDragAndDropDirective];

const modules = [FormsModule, ReactiveFormsModule, MaterialModule];

@NgModule({
    imports: [CommonModule, ...modules, SelectAllOptionDirective],
    declarations: [
        ...components,
        ...pipes,
        ...directives,
        DateTimePickerComponent,
        DashboardTypePipe,
        ArchiveRequestModalComponent,
        TrainingAccessRequestComponent,
        InfoModalComponent,
        InfoIconComponent,
    ],
    exports: [
        ...components,
        ...pipes,
        ...directives,
        ...modules,
        DateTimePickerComponent,
        DashboardTypePipe,
        InfoModalComponent,
        InfoIconComponent,
        SelectAllOptionDirective,
    ],
})
export class SharedModule {}
