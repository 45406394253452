<div class="new-design">
    <div>
        <mat-icon [inline]="true" class="clickable" fontSet="fas" fontIcon="fa-times" (click)="dialogRef.close()"></mat-icon>
    </div>

    <div class="dialog-content" #content>
        <!--        <div *ngIf="contentLoaded">-->
        <!--            <ng-container [ngSwitch]="pointType.name">-->
        <!--                <ng-container *ngSwitchCase="'INSITE'">-->
        <!--                    <ng-container [ngTemplateOutlet]="insite"></ng-container>-->
        <!--                </ng-container>-->

        <!--                <ng-container *ngSwitchCase="'TEARSHEET'">-->
        <!--                    <ng-container [ngTemplateOutlet]="tearsheet"></ng-container>-->
        <!--                </ng-container>-->

        <!--                &lt;!&ndash;        <ng-container *ngSwitchCase="'USER_CREATED'">&ndash;&gt;-->
        <!--                &lt;!&ndash;          <ng-container [ngTemplateOutlet]="userCreated"></ng-container>&ndash;&gt;-->
        <!--                &lt;!&ndash;        </ng-container>&ndash;&gt;-->
        <!--            </ng-container>-->
        <!--        </div>-->

        <p>Modal Under Construction</p>
    </div>

    <div class="dialog-actions"></div>
</div>

<!--<ng-template #tearsheet>-->
<!--    &lt;!&ndash;Title&ndash;&gt;-->
<!--    <div class="data-container">-->
<!--        <span class="sub-header">{{ mi['type'].value }}</span>-->
<!--        <h2>{{ datapoint.name }}</h2>-->
<!--    </div>-->

<!--    &lt;!&ndash;General Info&ndash;&gt;-->
<!--    <div class="data-container">-->
<!--        <span class="sub-header">General Information</span>-->
<!--        <p *ngFor="let info of generalSection">{{ getDisplay(info) }} {{ getValue(info) }}</p>-->
<!--    </div>-->

<!--    &lt;!&ndash;Location&ndash;&gt;-->
<!--    <div class="data-container">-->
<!--        <span class="sub-header">Location</span>-->
<!--        <p *ngFor="let key of locationInfo | keys">-->
<!--            {{ locationInfo[key] }}-->
<!--        </p>-->
<!--    </div>-->

<!--    &lt;!&ndash;Media&ndash;&gt;-->
<!--    <div class="data-container">-->
<!--        <span class="sub-header">Media</span>-->
<!--        <div class="media-container">-->
<!--            <ng-container *ngFor="let key of socialInfo | keys">-->
<!--                <mat-icon-->
<!--                    *ngIf="socialInfo[key].url"-->
<!--                    [fontSet]="socialInfo[key].iconType"-->
<!--                    [fontIcon]="socialInfo[key].iconName"-->
<!--                    (click)="navigateToUrl(socialInfo[key].url)"-->
<!--                    class="clickable media-icon">-->
<!--                </mat-icon>-->
<!--            </ng-container>-->
<!--        </div>-->
<!--    </div>-->

<!--    &lt;!&ndash;Helpful links&ndash;&gt;-->
<!--    <div class="data-container">-->
<!--        <span class="sub-header">Helpful Links</span>-->
<!--        <ng-container *ngFor="let info of linksSection">-->
<!--            <a class="helpful-link" *ngIf="getValue(info).startsWith('http')" [href]="getValue(info)">{{ getDisplay(info) }}</a>-->
<!--        </ng-container>-->
<!--    </div>-->

<!--    &lt;!&ndash;Data&ndash;&gt;-->
<!--    <ng-container *ngFor="let info of dataSection">-->
<!--        <div class="data-container">-->
<!--            <span class="sub-header">{{ getDisplay(info) }}</span>-->
<!--            <p class="metadata-text">{{ getValue(info) }}</p>-->
<!--        </div>-->
<!--    </ng-container>-->
<!--</ng-template>-->

<!--<ng-template #insite>-->
<!--    <ng-container *ngFor="let info of dataSection">-->
<!--        <div class="data-container">-->
<!--            <span class="sub-header">{{ getDisplay(info) }}</span>-->
<!--            <p class="metadata-text">{{ getValue(info) }}</p>-->
<!--        </div>-->
<!--    </ng-container>-->
<!--</ng-template>-->
