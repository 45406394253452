<div class="dialog-header">
    <div class="filter-header">Natural Gas Notices Settings</div>
    <mat-icon inline class="clickable" fontSet="fas" fontIcon="fa-times" (click)="dialogRef.close()"></mat-icon>
</div>

<div #content class="dialog-content">
    <span class="dialog-description"> View Critical or Non-Critical Notices provided by Genscape </span>

    <div class="filter-bar">
        <mat-button-toggle-group [value]="selectedNotice">
            <mat-button-toggle *ngFor="let level of noticesLevels" [value]="level" (change)="changeNotice(level)">
                {{ level.toUpperCase() }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>

<div class="dialog-actions">
    <button mat-flat-button color="primary" (click)="configureWidget()" [disabled]="disableUpdate()">Update Widget</button>
</div>
