<div class="map-container">
    <div class="map-frame">
        <div #leafletMap class="map"></div>
    </div>
</div>

<div class="loading-bar" *ngIf="mapService.layersLoading !== 0 || identifyService.loadingFeatureInfo">
    <p>Loading.</p>
</div>

<div class="map-controls">
    <div class="zoom-controls" *ngIf="mapOptions.show.zoom">
        <div *ngIf="useMobileLayout()">
            <button mat-mini-fab (click)="sidebarChanged()">
                <mat-icon inline fontSet="fas" fontIcon="fa-layer-group"></mat-icon>
            </button>
        </div>

        <div *ngIf="isFullscreen">
            <button mat-mini-fab (click)="getSourceData()">
                <mat-icon inline fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
            </button>
        </div>

        <div *ngIf="isFullscreen">
            <button mat-mini-fab (click)="getCoveredUtilities()">
                <mat-icon inline fontSet="fas" fontIcon="fa-industry"></mat-icon>
            </button>
        </div>

        <div>
            <button mat-mini-fab (click)="toggleFullscreen()">
                <mat-icon inline fontSet="fas" [fontIcon]="isFullscreen ? 'fa-compress' : 'fa-expand'"></mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="map-dates" *ngIf="mapOptions.show.refresh">
    <mat-chip *ngIf="isFullscreen" class="date-range clickable bold-border">
        <div class="chip-content">
            <mat-icon fontSet="fas" fontIcon="fa-calendar" inline></mat-icon>
            <span
                >{{ mapService.mapDateRange.getValue().startDate | momentDate }} -{{
                    mapService.mapDateRange.getValue().endDate | momentDate
                }}</span
            >
            <mat-icon fontSet="fas" fontIcon="fa-angle-right" inline></mat-icon>
        </div>
    </mat-chip>
</div>

<div class="coverage" *ngIf="mapOptions.show.coverage">
    <mat-chip class="refresh-time">
        <div class="chip-content">
            <mat-icon fontSet="fas" fontIcon="fa-redo" inline></mat-icon>
            <span>{{ mapService.lastRefreshTime | momentDate : 'hh:mm A' }}</span>
        </div>
    </mat-chip>

    <mat-chip *ngIf="isFullscreen" class="bold-border">
        <span>
            Current Coverage:
            <span *ngIf="mapService.coveragePercent !== 0">{{ mapService.coveragePercent | percent : '1.2-2' }}</span>
            <span *ngIf="mapService.coveragePercent === 0">N/A</span>
        </span>
    </mat-chip>
</div>
