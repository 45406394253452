<div class="hide-overflow">
    <div #header class="widget-header large">
        <p class="title">HEADOUT Data</p>

        <div class="btn-container">
            <button mat-stroked-button (click)="goToReport()">
                <mat-icon class="icon" fontSet="fas" fontIcon="fa-file-alt" inline></mat-icon>
                <span>View Report</span>
            </button>

            <mat-form-field class="filter-select active" appearance="outline">
                <mat-label>Advisory</mat-label>
                <mat-select placeholder="Advisory" [value]="selectedAdvisory" (selectionChange)="updateAdvisory($event.value)">
                    <mat-option *ngFor="let advisory of advisories" [value]="advisory">{{ advisory.resultId }}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="export">
                <button
                    mat-button
                    class="ei-export"
                    matTooltip="Export"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    [matMenuTriggerFor]="menu">
                    <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div #content class="widget-content">
        <div class="table-container" [class.invisible]="maskOptions.showMask">
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="customersOut"
                matSortDirection="desc">
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        State/Territory
                        <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.state | stateName }}</td>
                </ng-container>
                <ng-container matColumnDef="county">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        County
                        <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.county }}</td>
                </ng-container>
                <ng-container matColumnDef="customersOut">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Predicted Customers Out
                        <eaglei-table-sort-header [columnName]="'customersOut'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.customersOut | eagleiNumber }}</td>
                </ng-container>
                <ng-container matColumnDef="totalCustomers">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Total Customers
                        <eaglei-table-sort-header [columnName]="'totalCustomers'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.totalCustomers | eagleiNumber }}</td>
                </ng-container>
                <ng-container matColumnDef="percentOut">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Predicted Percent Out
                        <eaglei-table-sort-header [columnName]="'percentOut'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.percentOut | percent : '1.2-2' }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
            </table>
        </div>

        <div class="auto-mask hide-mask" *ngIf="maskOptions.showMask">
            <span class="mask-message">
                <span>{{ maskOptions.text }}</span>
            </span>
        </div>
    </div>

    <div #footer class="widget-footer">
        <mat-paginator [pageSize]="7" hidePageSize showFirstLastButtons></mat-paginator>
    </div>
</div>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
