<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend">
            <div class="legends">
                <span class="header">Utility Pole</span>
                <div class="icon-container">
                    <img class="pole-icon" src="/dist/images/pole-detection/pole_circle_green.svg" />
                    <span class="label"> Undamaged</span>
                </div>
                <div class="icon-container">
                    <img class="pole-icon" src="/dist/images/pole-detection/pole_circle_red.svg" />
                    <span class="label"> Damaged</span>
                </div>

                <span class="header">Substation</span>
                <div class="icon-container">
                    <img class="pole-icon" src="/dist/images/pole-detection/substation_circle_green.svg" />
                    <span class="label"> Undamaged</span>
                </div>
                <div class="icon-container">
                    <img class="pole-icon" src="/dist/images/pole-detection/substation_circle_red.svg" />
                    <span class="label"> Damaged</span>
                </div>
            </div>
        </div>
    </div>

    <div [formGroup]="filterControlGroup">
        <mat-form-field class="filter-select" appearance="outline">
            <mat-label>Infrastructure Type</mat-label>
            <mat-select (selectionChange)="updateFilter()" formControlName="infrastructure">
                <mat-option value="all">All</mat-option>
                <mat-option value="pole">Utility Pole</mat-option>
                <mat-option value="substation">Substation</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select (selectionChange)="updateFilter()" formControlName="status">
                <mat-option value="all">All</mat-option>
                <mat-option value="damaged">Damaged</mat-option>
                <mat-option value="undamaged">Undamaged</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-container>
