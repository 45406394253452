<div class="header" mat-dialog-title>
    <div class="title">{{ title }}</div>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div [class.hide-table]="showMask">
        <div class="table-container">
            <table
                #materialTable
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                [matSortActive]="defaultSort"
                [matSortDirection]="defaultSortDirection">
                <ng-container *ngFor="let column of columnDefs" [matColumnDef]="column.columnName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [class.no-click]="column.sortValue === undefined">
                        {{ column.displayName }}
                        <eaglei-table-sort-header
                            *ngIf="column.sortValue !== undefined"
                            [columnName]="column.columnName"
                            [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        <div *ngIf="column.columnName === columnNames[0]" class="override-dot">
                            <div [class.invisible]="!info.hasOverrideData" [style.backgroundColor]="overrideColor"></div>
                        </div>
                        <span>{{ column.getValue(info) }}</span>
                        <span *ngIf="column.subscript" [ngClass]="column.cellClass">{{ column.subscript(info) | titlecase }}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
            </table>
        </div>
        <mat-paginator
            [pageSize]="10"
            [pageSizeOptions]="[10, 25, 50, 75, 100]"
            [length]="totalDataLength"
            showFirstLastButtons></mat-paginator>
    </div>

    <ng-container *ngIf="showMask">
        <div class="loading-mask">
            <span>{{ maskText }}</span>
        </div>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="override-dot">
        <div [style.backgroundColor]="overrideColor"></div>
    </div>
    <span class="override-legend-text">Overrides present</span>
</mat-dialog-actions>
