<div class="dialog-header">
    <span>{{ headerText }}</span>
    <mat-icon class="clickable" fontIcon="close" (click)="closeModal()"></mat-icon>
</div>

<div #content class="dialog-content">
    <div *ngIf="showMask" class="loading-mask">
        <span>{{ maskText }}</span>
    </div>

    <table mat-table #materialTable [dataSource]="dataSource" multiTemplateDataRows [class.invisible]="showMask">
        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef class="arrow-cell icon-column"></th>
            <td mat-cell *matCellDef="let feature" class="arrow-cell icon-column">
                <i class="fa fa-fw fa-chevron-right arrow" [class.rotate-arrow]="expanded === feature"></i>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Layer Name</th>
            <td mat-cell *matCellDef="let feature">
                <span>
                    <b>{{ feature.layerName }}</b> - {{ feature.name }}</span
                >
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let feature" [attr.colspan]="displayColumns.length">
                <div class="hide-expanded-row" [class.show-expanded-row]="feature === expanded">
                    <div>
                        <ng-container *ngIf="feature.data.length >= 1; else noData">
                            <ng-container *ngFor="let element of feature.data; let odd = odd">
                                <div class="table-row" *ngIf="!element.hideInLocationSearch">
                                    <ng-container *ngIf="element.isLink">
                                        <a [href]="element.value" target="_blank">
                                            {{ element.label || element.value }}
                                        </a>
                                    </ng-container>

                                    <ng-container *ngIf="!element.isLink">
                                        <span class="data-label">{{ element.label }}</span>
                                        <span class="data-value">{{ element.value }}</span>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #noData>
                            <span class="additional">No Additional Information</span>
                        </ng-template>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayColumns"
            [class.example-expanded-row]="expanded === row"
            (click)="toggleExpansion(row)"
            class="clickable"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" style="height: 0"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons [class.invisible]="showMask"></mat-paginator>
</div>

<div class="dialog-actions">
    <div *ngIf="locationSearchService.loading" class="loading-spinner">
        <mat-spinner color="accent" diameter="20"></mat-spinner>
        <span> Fetching data...</span>
    </div>

    <button mat-flat-button color="primary" [matMenuTriggerFor]="menu">Export</button>

    <mat-menu #menu="matMenu" [overlapTrigger]="true">
        <button (click)="exportZipFile()" mat-menu-item>Export As .zip</button>
        <button (click)="exportData()" mat-menu-item>Export As .csv</button>
    </mat-menu>
</div>
