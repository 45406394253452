<div #header class="widget-header">
    <p class="title">UAS Pole Detection</p>
    <button mat-stroked-button (click)="linkToReport()">View Report</button>
</div>

<div #content class="widget-content table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="flightDate" matSortDirection="desc">
        <ng-container matColumnDef="flightDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Flight Date
                <eaglei-table-sort-header [columnName]="'flightDate'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info" class="ellipsis">
                {{ info.flightDate | momentDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="confidence">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Confidence
                <eaglei-table-sort-header [columnName]="'confidence'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">
                {{ info.confidence | percent : '1.2-2' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="damaged">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Status
                <eaglei-table-sort-header [columnName]="'damaged'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">
                {{ info.damaged ? 'Damaged' : 'Undamaged' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="detections">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Detections
                <eaglei-table-sort-header [columnName]="'detections'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">
                {{ info.detections }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>

    <div class="auto-mask hide-mask" *ngIf="dataSource?.filteredData?.length === 0">
        <p class="mask-message">There are no collections for the current filters</p>
    </div>
</div>

<div #footer class="widget-footer">
    <mat-paginator hidePageSize showFirstLastButtons [pageSize]="6"></mat-paginator>
</div>
