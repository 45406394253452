import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {HistoricalOutageData} from '../classes/historical-outage-data';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {LayerStyleService} from '../services/layer-style.service';
import {FeatureStyle} from '../interfaces/style.interface';
import {MapService} from '../../map/services/map.service';
import {DataService} from '../../../services/data.service';
import {State} from '../../outage/classes/state';
import {ApplicationConfig, IGeometry} from '../../../classes/application-config';
import * as moment from 'moment';
import {MapLayerPopover} from '../classes/map-layer-popover';
import {GenPopoverDataType} from 'frontend/generated/serverModels/GenPopoverDataType';
import {GenOutageAggregationLevel} from '../../../../../generated/serverModels/GenOutageAggregationLevel';

export class LeafletNomSource extends LeafletVectorSource<HistoricalOutageData> {
    private filterMap = new Map<string, (feature: any) => boolean>();
    private stateList: string[] = [];

    // TODO: Find better way of handling Popover Data set
    private nomPopoverInfo: MapLayerPopover[] = [];

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
        this.nomPopoverInfo = [...this.layerInfo.popoverData];
    }

    public processFeatures(features: HistoricalOutageData[]): void {
        this.isTileMap = false;
        this.leafletFeatures = features?.map((v) => {
            // Changing the title property because when on the COBSR, it changes the value displayed in the table
            // We may want to look at
            // a: Manually setting what columns are shown at each aggregation in the COBSR, instead of using title
            // b: Add a new popover element for state if we are looking at county or zip code data
            // This may change when we update the COBSR to use paginated endpoints...
            if (v.countyName) {
                v['popoverTitle'] = `${v.countyName} (${v.stateName})`;
            }
            return new LeafletFeature<HistoricalOutageData>().convert(v).buildPopover(this.layerInfo);
        });

        const featureStyle = (feature: LeafletFeature<HistoricalOutageData>) => {
            const color = LayerStyleService.colorOutage(feature.properties);
            // TODO remove leaflet when all old files are cleaned up.
            const key = `leaflet-nom-${color}-${
                feature.properties.currentOutageHasOverrideData
            }-${ApplicationConfig.showOverrideColor.getValue()}`;

            let style: FeatureStyle = LayerStyleService.layerStyles.get(key);

            if (!style) {
                style = {
                    fillColor: color,
                    fillOpacity: 1,
                    color: '#000000',
                    weight: 1,
                };

                if (feature.properties.currentOutageHasOverrideData && ApplicationConfig.showOverrideColor.getValue()) {
                    // To see possible properties check the leaflet.pattern plugin.
                    const so = {
                        angle: 45,
                        weight: 7,
                        color,
                        spaceColor: ApplicationConfig.currentUserPreferences.getValue().getOverrideColor(),
                        spaceOpacity: 1,
                    };
                    const stripe = new (L as any).StripePattern(so);
                    stripe.addTo(MapService.mapRef);
                    style.fillPattern = stripe;
                }
            }

            return style;
        };

        this.layerInfo.popoverData = [...this.nomPopoverInfo];
        const layerPopover = (feature: LeafletFeature<HistoricalOutageData>, layer: L.Layer) => {
            // TODO: Find cleaner way of handling this

            // Used to set what the title property should be.
            this.layerInfo.popoverData.find((element) => element.title).propertyName = feature.properties.countyName
                ? 'popoverTitle'
                : 'title';

            feature.popoverData = this.layerInfo.createPopover(feature?.properties);
            const total = feature?.popoverData?.find((p) => p.label === 'Total Customers');
            const isModeled = feature.properties.coveredCustomers === feature.properties.modelCount;
            total.setSubValue(!!feature.properties.coveredCustomers ? `(${isModeled ? 'Modeled' : 'Collected'})` : '');
            total.subValClass = 'county-customer';
            this.initializePopoverInteractions(feature, layer, 'hover', false, feature.popoverData);
        };

        const config = {
            pane: 'nomPane',
            style: featureStyle,
            smoothFactor: 0.5,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
            filter: (feature: any) => {
                if (this.filterMap.size > 0) {
                    return Array.from(this.filterMap.values()).every((func) => func(feature));
                }
                return true;
            },
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config as any);
    }

    public fetchNomTiles(timeStamp?: moment.Moment): void {
        this.isTileMap = true;

        const tileUrl = `EI_TILESERVER/outage_data.zip_outage_tile_for_run_start_time/{z}/{x}/{y}.pbf`;
        const params: any = {
            states: this.stateList.join(),
            rst: ApplicationConfig.roundMinute(timeStamp).format(),
        };
        const filters = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');

        const nomStyles = {
            'outage_data.zip_outage_tile_for_run_start_time': (props: any, zoom: number) => {
                const setProps = {
                    currentOutage: props.currentOutage,
                    percentOut: props.percentOut,
                    currentOutageHasOverrideData: false,
                    coveredCustomers: props.coveredCustomers,
                };

                const color = LayerStyleService.colorOutage(setProps as any);

                return {
                    fillColor: color,
                    fillOpacity: 1,
                    color: '#000000',
                    fill: true,
                    weight: 0.5,
                };
            },
        };

        const layerPopover = (feature, layer: L.Layer) => {
            this.initializePopoverInteractions(feature, layer, 'hover', false);
        };

        this.layerInfo.popoverData = [
            new MapLayerPopover({
                display: undefined,
                ordering: 100,
                propertyName: 'zipcode',
                title: true,
                type: GenPopoverDataType.STRING,
            } as any),
            new MapLayerPopover({
                display: 'Customers Out',
                ordering: 150,
                propertyName: 'currentOutage',
                title: false,
                type: GenPopoverDataType.NUMBER,
                valueFormat: '1.0-0',
            } as any),
            new MapLayerPopover({
                display: 'Total Customers',
                ordering: 200,
                propertyName: 'coveredCustomers',
                title: false,
                type: GenPopoverDataType.NUMBER,
                valueFormat: '1.0-0',
            } as any),
            new MapLayerPopover({
                display: 'Percent Out',
                ordering: 300,
                propertyName: 'percentOut',
                title: false,
                type: GenPopoverDataType.PERCENT,
            } as any),
            new MapLayerPopover({
                display: 'Outage Timestamp',
                ordering: 400,
                propertyName: 'currentOutageRunStartTime',
                title: false,
                type: GenPopoverDataType.DATE,
            } as any),
        ];

        const options = {
            vectorTileLayerStyles: nomStyles,
            subdomains: '0123',
            interactive: true,
            pane: 'nomPane',
            getFeatureId: (f) => {
                return f;
            },
        };

        this.source = (L as any).vectorGrid.protobuf(`${ApplicationConfig.proxyPrefix}${tileUrl}?${filters}`, options);

        this.source.on('loading', () => {
            this.layerInfo.startLoading();
        });

        this.source.on('load', () => {
            this.layerInfo.endLoading();
        });

        layerPopover(undefined, this.source);

        this.addToMap(true);
    }

    public updateLocationFilter(states: State[], zoomToFeatures: boolean = false): void {
        const locationFilterForSource = (f: any): boolean => {
            const outage: HistoricalOutageData = f.properties;
            return states.map((s) => s.abbreviation).includes(outage?.stateName);
        };

        this.stateList = states.map((s) => s.abbreviation);
        this.updateFilter('location', locationFilterForSource);

        if (zoomToFeatures) {
            this.fitToFeatures();
        }
    }

    public redraw(): void {
        const key = 'location';
        this.updateFilter(key, this.filterMap.get(key));
    }

    updateFilter(type: string, ff: (f: any) => boolean) {
        this.filterMap.set(type, ff);

        if (this.source) {
            const jsonObj = {
                type: 'FeatureCollection',
                features: this.leafletFeatures,
            };

            if (this.isTileMap) {
                this.removeFromMap();
                this.fetchNomTiles();
                this.changeOpacity(this.layerInfo.opacity);
            } else {
                this.source.clearLayers();
                this.source.addData(jsonObj as any);
                this.changeOpacity(this.layerInfo.opacity);
            }
        }
    }

    public fitToFeatures(): void {
        // Sometimes, based off of the data source, you don't always know if the `getLayers` method will exist
        // For now if it's not present will jump out - jdillon
        const states = new Set<string>();

        if (!this.source?.getLayers) {
            this.stateList.forEach((abbreviation) => states.add(abbreviation));
        } else {
            this.source.getLayers().forEach((layer: any) => states.add(layer.feature.properties.stateName));
        }

        const allStates = ApplicationConfig.geometries.get(GenOutageAggregationLevel.state);
        let zoomTo: IGeometry[] = allStates.filter((stateGeometryInfo) => states.has(stateGeometryInfo.name));

        // if all go to conus
        if (allStates.length === states.size) {
            zoomTo = allStates.filter((geom) => {
                return !DataService.nonConusStateAbbreviations.includes(geom.name);
            });
        } else if (states.size === 1 && states.has('AK')) {
            // if only ak zoom to main land
            (this.mapRef || MapService.mapRef).fitBounds(MapService.alaskaBounds);
            return;
        } else if (states.size > 1 && (states.has('AK') || states.has('HI') || states.has('AS') || states.has('GU') || states.has('MP'))) {
            // if more states and contains bounds over IDL - zoom to conus
            // TODO: Add logic for edge cases
            zoomTo = allStates.filter((geom) => {
                return !DataService.nonConusStateAbbreviations.includes(geom.name);
            });
        }

        if (zoomTo.length > 0) {
            const fc = {
                type: 'FeatureCollection',
                features: zoomTo.map((state) => {
                    return {
                        type: 'Feature',
                        properties: {},
                        geometry: JSON.parse(state.boundary),
                    };
                }),
            };

            const geojson = L.geoJSON(fc as any);

            (this.mapRef || MapService.mapRef).fitBounds(geojson.getBounds());
        }
    }
}
