<!--This is used for $event.target.value since target is a generic type but we know it is an HTMLElement-->
<!--suppress TypeScriptUnresolvedVariable -->

<div class="filter-bar ignore-last" [class.ignore-last]="isManagement">
    <section class="filters">
        <mat-form-field class="filter-select" [class.active]="isTextFilterActive()">
            <mat-label *ngIf="isTextFilterActive()">Search Users</mat-label>

            <input matInput placeholder="Search Users" (keyup)="searchUsers($event.target.value)" />

            <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
        </mat-form-field>

        <ng-container *ngIf="isManagement">
            <mat-form-field class="filter-select" [class.active]="selectedRoles.length > 0">
                <mat-label *ngIf="selectedRoles.length > 0">Filter Roles</mat-label>
                <mat-select [(ngModel)]="selectedRoles" (selectionChange)="filterRoles($event)" placeholder="Filter Roles" multiple>
                    <mat-option [value]="undefined">Needing Approval</mat-option>
                    <mat-option *ngFor="let role of selectRoles" [value]="role"> {{ role | roleDefinition }}</mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="isTrainingEnvironment">
                <button mat-flat-button color="primary" class="summary-btn" (click)="openCreateTrainingUsersModal()">
                    Create Training Users
                </button>
            </ng-container>

            <ng-container *ngIf="!isProductionEnvironment">
                <button mat-flat-button color="primary" class="summary-btn" (click)="syncUsersFromProduction()">
                    Sync Users From Production
                </button>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!isManagement">
            <mat-button-toggle-group name="status" [value]="selectedFilter" class="text-list">
                <mat-button-toggle value="all" (change)="toggleStatus($event.value)">ALL</mat-button-toggle>
                <mat-button-toggle value="enabled" (change)="toggleStatus($event.value)">ENABLED</mat-button-toggle>
                <mat-button-toggle value="disabled" (change)="toggleStatus($event.value)">DISABLED</mat-button-toggle>
                <mat-button-toggle value="notApproved" (change)="toggleStatus($event.value)">NOT APPROVED</mat-button-toggle>
                <mat-button-toggle value="login" (change)="toggleStatus($event.value)">LOGIN</mat-button-toggle>
                <mat-button-toggle value="join" (change)="toggleStatus($event.value)">JOIN</mat-button-toggle>
            </mat-button-toggle-group>

            <eaglei-date-range
                class="hide-horizontal"
                [class.show]="selectedFilter === 'join' || selectedFilter === 'login'"
                [newDesign]="true"
                [ignoreMinDate]="true"
                [showTime]="false"
                [start]="startDate"
                [end]="endDate"
                (change)="updateDates($event)">
            </eaglei-date-range>
        </ng-container>
    </section>

    <button
        mat-stroked-button
        class="export-button"
        (click)="exportTable()"
        matTooltip="Export"
        matTooltipPosition="above"
        matTooltipShowDelay="250">
        <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
    </button>
</div>

<mat-card>
    <mat-card-content>
        <div class="table-container">
            <table
                #materialTable
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="lastLogin"
                matSortDirection="desc"
                multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="med-col">
                        Name
                        <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let user" class="med-col">
                        <p class="ellipsis">{{ user.fullName }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Email
                        <eaglei-table-sort-header [columnName]="'email'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let user">
                        <p class="ellipsis">{{ user.email }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Username
                        <eaglei-table-sort-header [columnName]="'username'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let user">
                        <p class="ellipsis">{{ user.username }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="enabled">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
                        Enabled
                        <eaglei-table-sort-header [columnName]="'enabled'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let user" class="center">
                        <mat-icon *ngIf="user.enabled" fontSet="fa" fontIcon="fa-check"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="organization">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Organization
                        <eaglei-table-sort-header [columnName]="'organization'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let user">
                        <p class="ellipsis">{{ user.organization }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="joined">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Joined
                        <eaglei-table-sort-header [columnName]="'joined'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let user">
                        <p>{{ user.joinDate | momentDate : 'MM/DD/YYYY' }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastLogin">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Last Login
                        <eaglei-table-sort-header [columnName]="'lastLogin'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let user">
                        <p>{{ user.lastLoginDate | momentDate }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="fed">
                    <th mat-header-cell *matHeaderCellDef class="center">Federal</th>
                    <td mat-cell *matCellDef="let user" class="center">
                        <mat-icon *ngIf="isFed(user)" fontSet="fa" fontIcon="fa-check"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="doe">
                    <th mat-header-cell *matHeaderCellDef class="center">DOE</th>
                    <td mat-cell *matCellDef="let user" class="center">
                        <mat-icon *ngIf="isDoe(user)" fontSet="fa" fontIcon="fa-check"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="admin">
                    <th mat-header-cell *matHeaderCellDef class="center">Admin</th>
                    <td mat-cell *matCellDef="let user" class="center">
                        <mat-icon *ngIf="user.isAdmin()" fontSet="fa" fontIcon="fa-check"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="center icon-column"></th>
                    <td mat-cell *matCellDef="let user" class="center icon-column">
                        <button mat-icon-button [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation()">
                            <mat-icon fontSet="fas" fontIcon="fa-ellipsis-v"></mat-icon>
                        </button>

                        <mat-menu #actionMenu="matMenu" overlapTrigger>
                            <button mat-menu-item (click)="sendPasswordReset(user)">
                                <mat-icon fontSet="fas" fontIcon="fa-lock"></mat-icon>
                                Send Password Reset email
                            </button>

                            <button mat-menu-item *ngIf="!user.emailVerified" (click)="verifyEmail(user)">
                                <mat-icon fontSet="fas" fontIcon="fa-envelope"></mat-icon>
                                Send Verification Email
                            </button>

                            <button mat-menu-item (click)="openReviewModal(user)">
                                <mat-icon fontSet="fas" fontIcon="fa-user-cog"></mat-icon>
                                Review User
                            </button>

                            <div
                                matTooltip="User must be enabled to impersonate."
                                matTooltipPosition="left"
                                matTooltipShowDelay="250"
                                [matTooltipDisabled]="user.enabled">
                                <button mat-menu-item (click)="impersonateUser(user)" [disabled]="!user.enabled">
                                    <mat-icon fontSet="fas" fontIcon="fa-user-secret"></mat-icon>
                                    Impersonate User
                                </button>
                            </div>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: columnNames"
                    (click)="gotoProfile(row)"
                    class="hoverable"
                    [class.clickable]="isManagement"
                    [class.selected-row]="row.expanded"
                    [class.inactive]="!row.enabled"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
