<div class="hide-overflow">
    <div #header class="widget-header large">
        <p class="title">
            Outage Restoration
            <br />
            <span class="attribution">Time Interval: 6 Hours</span>
        </p>

        <div class="form">
            <button mat-stroked-button (click)="goToReport()">
                <mat-icon class="icon" fontSet="fas" fontIcon="fa-file-alt" inline></mat-icon>
                <span>View Report</span>
            </button>

            <mat-form-field class="filter-select active" appearance="outline">
                <mat-label>Filter By</mat-label>
                <mat-select placeholder="Filter By" [value]="filterBy" (selectionChange)="updateLevel($event.value)">
                    <mat-option [value]="'state'">States/Territories</mat-option>
                    <mat-option [value]="'county'">County</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="export">
                <button
                    mat-button
                    class="ei-export"
                    matTooltip="Export"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    [matMenuTriggerFor]="menu">
                    <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div #content class="widget-content">
        <div class="table-contatiner" [class.invisible]="dataSource?.filteredData?.length === 0">
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="percentRestored"
                matSortDirection="desc">
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="state">
                        State/Territory
                        <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"> </eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage" class="state">
                        {{ outage.state }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="county">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        County
                        <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"> </eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage">
                        {{ outage.countyName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalCustomers">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Total Customers
                        <eaglei-table-sort-header [columnName]="'totalCustomers'" [sort]="sort"> </eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage">
                        {{ outage.numCustomers | eagleiNumber }}
                        <span class="county-customer"> {{ getCountyCustomerText(outage) }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="outageCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Outage Count
                        <eaglei-table-sort-header [columnName]="'outageCount'" [sort]="sort"> </eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage">
                        {{ outage.outageCountTotal | eagleiNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="restorationCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Restoration Count
                        <eaglei-table-sort-header [columnName]="'restorationCount'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage">
                        {{ outage.restorationTotal | eagleiNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="percentRestored">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Percent Restored
                        <eaglei-table-sort-header [columnName]="'percentRestored'" [sort]="sort"> </eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage">
                        <div *ngIf="outage.knownPercent">{{ outage.percentRestored | eagleiNumber }}%</div>
                        <div *ngIf="!outage.knownPercent">Not Available</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="trend">
                    <th mat-header-cell *matHeaderCellDef>Trending</th>
                    <td mat-cell *matCellDef="let outage">
                        <mat-icon
                            class="trend"
                            [class.up]="outage.trendIcon === 'trending_up'"
                            [class.down]="outage.trendIcon === 'trending_down'"
                            >{{ outage.trendIcon }}</mat-icon
                        >
                    </td>
                </ng-container>

                <ng-container matColumnDef="maxOutage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Max Outage
                        <eaglei-table-sort-header [columnName]="'maxOutage'" [sort]="sort"> </eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage">
                        {{ outage.maxOutageCount | eagleiNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="maxRestoration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Max Restoration
                        <eaglei-table-sort-header [columnName]="'maxRestoration'" [sort]="sort"> </eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage">
                        {{ outage.maxRestorationCount | eagleiNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastTimestamp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Last Timestamp
                        <eaglei-table-sort-header [columnName]="'lastTimestamp'" [sort]="sort"> </eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let outage">
                        {{ outage.timestamp | momentDate : 'MM/DD/YYYY' }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
            </table>

            <div class="auto-mask hide-mask" *ngIf="dataSource?.filteredData?.length === 0">
                <span class="mask-message">
                    <span>No Data matched current filters</span>
                </span>
            </div>
        </div>
    </div>

    <div #footer class="widget-footer">
        <mat-paginator [pageSize]="5" hidePageSize showFirstLastButtons></mat-paginator>
    </div>
</div>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
