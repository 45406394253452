
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenPopoverDataType {
    static readonly STRING: GenPopoverDataType = new GenPopoverDataType('STRING');
    static readonly NUMBER: GenPopoverDataType = new GenPopoverDataType('NUMBER');
    static readonly PERCENT: GenPopoverDataType = new GenPopoverDataType('PERCENT');
    static readonly DATE: GenPopoverDataType = new GenPopoverDataType('DATE');
    static readonly LINK: GenPopoverDataType = new GenPopoverDataType('LINK');
    static readonly MODAL: GenPopoverDataType = new GenPopoverDataType('MODAL');
    static readonly DOWNLOAD: GenPopoverDataType = new GenPopoverDataType('DOWNLOAD');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'STRING') return GenPopoverDataType.STRING;
        if (name === 'NUMBER') return GenPopoverDataType.NUMBER;
        if (name === 'PERCENT') return GenPopoverDataType.PERCENT;
        if (name === 'DATE') return GenPopoverDataType.DATE;
        if (name === 'LINK') return GenPopoverDataType.LINK;
        if (name === 'MODAL') return GenPopoverDataType.MODAL;
        if (name === 'DOWNLOAD') return GenPopoverDataType.DOWNLOAD;
        throw new Error('Unexpected value for GenPopoverDataType:' + name);
    }

    static values(): GenPopoverDataType[] {
        return [
            GenPopoverDataType.STRING,
            GenPopoverDataType.NUMBER,
            GenPopoverDataType.PERCENT,
            GenPopoverDataType.DATE,
            GenPopoverDataType.LINK,
            GenPopoverDataType.MODAL,
            GenPopoverDataType.DOWNLOAD,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

