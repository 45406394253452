import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ApplicationConfig} from '../../../../classes/application-config';
import {LocationSearchService} from '../../../map/services/location-search.service';
import {MapService} from '../../../map/services/map.service';
import {DrawType} from '../../../map/types/draw-type';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {MatDialog} from '@angular/material/dialog';
import {CoordinateDataModalComponent} from '../../../map/modals/coordinate-data-modal/coordinate-data-modal.component';
import {ModalConfig} from '../../../../classes/modal-config';
import {filter, takeUntil} from 'rxjs/operators';
import {SidebarService} from '../../../map/services/sidebar.service';
import {MapLayerCategory} from '../../classes/map-layer-category';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {IPoint} from '../../interfaces/point.interface';
import {UtilityLookupService} from '../../../map/services/utility-lookup.service';
import {UtilityLookupModalComponent} from '../../../map/modals/utility-lookup-modal/utility-lookup-modal.component';
import * as L from 'leaflet';

export type ToolSection = 'geom' | 'buffer' | 'utility' | 'routing';

interface IToolSelection {
    geom: boolean;
    buffer: boolean;
    utility: boolean;
    routing: boolean;
}

@Component({
    selector: 'eaglei-tool-list',
    templateUrl: './tool-list.component.html',
    styleUrls: ['./tool-list.component.scss'],
})
export class ToolListComponent implements OnInit, OnChanges, OnDestroy {
    @Input() layerCategories: MapLayerCategory[];
    @Input() activeLayer: string;

    public expanded: IToolSelection = {
        geom: false,
        buffer: false,
        utility: false,
        routing: false,
    };

    public selectedShape: DrawType = 'polygon';
    public coordinateGroup: UntypedFormGroup;

    // Routing Tool Properties
    public routingcoordinateGroup: UntypedFormGroup = new UntypedFormGroup({
        start: new UntypedFormGroup({
            latitude: new UntypedFormControl('', [Validators.min(-90), Validators.max(90)]),
            longitude: new UntypedFormControl('', [Validators.min(-180), Validators.max(180)]),
        }),
        stop: new UntypedFormGroup({
            latitude: new UntypedFormControl('', [Validators.min(-90), Validators.max(90)]),
            longitude: new UntypedFormControl('', [Validators.min(-180), Validators.max(180)]),
        }),
    });
    public isSelectingStart: boolean = false;
    private routingStartMarker: L.Marker;
    private routingStopMarker: L.Marker;
    public errorMessage: string = undefined;
    public searchingRoute: boolean = false;
    private pointEventSub: Subscription;

    private destroyed$ = new Subject();

    constructor(
        public locationSearchService: LocationSearchService,
        private dialog: MatDialog,
        private sidebarService: SidebarService,
        private utilityLookupService: UtilityLookupService
    ) {}

    public ngOnInit(): void {
        this.pointEventSub = this.locationSearchService.selectedPoint
            .pipe(
                filter((val) => val !== undefined && this.locationSearchService.pointSelection),
                takeUntil(this.destroyed$)
            )
            .subscribe((point) => {
                point.latitude = parseFloat(point.latitude.toFixed(4));
                point.longitude = parseFloat(point.longitude.toFixed(4));

                if (this.expanded.routing) {
                    if (this.isSelectingStart) {
                        this.routingcoordinateGroup
                            .get('start')
                            .patchValue({latitude: point.latitude, longitude: point.longitude}, {emitEvent: false, onlyself: true});

                        this.locationSearchService.drawPoint.next({
                            latitude: point.latitude,
                            longitude: point.longitude,
                            marker: this.routingStartMarker,
                        });
                        this.routingStartMarker = this.locationSearchService.drawPoint.value.marker;
                    } else {
                        this.routingcoordinateGroup
                            .get('stop')
                            .patchValue({latitude: point.latitude, longitude: point.longitude}, {emitEvent: false, onlyself: true});

                        this.locationSearchService.drawPoint.next({
                            latitude: point.latitude,
                            longitude: point.longitude,
                            marker: this.routingStopMarker,
                        });
                        this.routingStopMarker = this.locationSearchService.drawPoint.value.marker;
                    }

                    this.locationSearchService.pointSelection = !this.locationSearchService.pointSelection;
                } else {
                    this.coordinateGroup.controls.latitude.setValue(point.latitude);
                    this.coordinateGroup.controls.longitude.setValue(point.longitude);

                    if (this.expanded.utility) {
                        this.getUtilityInformation(point);
                    }
                }
            });

        const controls = {
            latitude: new UntypedFormControl('', [Validators.min(-90), Validators.max(90)]),
            longitude: new UntypedFormControl('', [Validators.min(-180), Validators.max(180)]),
        };
        this.coordinateGroup = new UntypedFormGroup(controls);

        this.routingcoordinateGroup = new UntypedFormGroup({
            start: new UntypedFormGroup({
                latitude: new UntypedFormControl('', [Validators.min(-90), Validators.max(90)]),
                longitude: new UntypedFormControl('', [Validators.min(-180), Validators.max(180)]),
            }),
            stop: new UntypedFormGroup({
                latitude: new UntypedFormControl('', [Validators.min(-90), Validators.max(90)]),
                longitude: new UntypedFormControl('', [Validators.min(-180), Validators.max(180)]),
            }),
        });

        this.setRoutingFormSub();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['activeLayer']?.currentValue !== 'tools') {
            this.expanded = {
                geom: false,
                buffer: false,
                utility: false,
                routing: false,
            };

            this.toggleUtilitySearch();
        }
    }

    public ngOnDestroy(): void {
        this.pointEventSub.unsubscribe();
        this.destroyed$.complete();
        this.destroyed$.unsubscribe();
    }

    public toggleSection(section: ToolSection): void {
        this.coordinateGroup.reset();

        for (const sectionKey in this.expanded) {
            if (sectionKey === section) {
                this.expanded[sectionKey] = !this.expanded[sectionKey];
            } else {
                this.expanded[sectionKey] = false;
            }
        }

        // Call this to make sure the cursor interactions return to normal
        this.toggleUtilitySearch();
    }

    // Buffer Search Methods
    public searchBuffer(): void {
        this.locationSearchService.searchLayer.clearLayers();
        const circle = this.locationSearchService.convertBufferToPolygon(
            this.coordinateGroup.controls.latitude.value,
            this.coordinateGroup.controls.longitude.value
        );
        const addedLayer: any = this.locationSearchService.searchLayer.addData(circle);
        this.locationSearchService.getFeaturesIntersectingLayer(this.locationSearchService.searchLayer);

        this.locationSearchService.searchLayer.bringToFront();
        this.openCoordinateModal();

        MapService.mapRef.flyToBounds(addedLayer.getBounds());
    }

    public clearBufferOptions(): void {
        this.coordinateGroup.reset();
        this.locationSearchService.bufferInMiles = 10;
        this.locationSearchService.searchLayer.clearLayers();
    }

    public bufferSearchValid(): boolean {
        return this.coordinateGroup.valid && this.coordinateGroup.value.latitude && this.coordinateGroup.value.longitude;
    }

    // Geometry Search Method
    /**
     * Disables the point selection ability since the user can not do both,
     * Clears the layer so it starts clean and searches within the drawn boundary
     */
    public searchGeometry(): void {
        this.locationSearchService.getFeaturesIntersectingLayer(this.locationSearchService.searchLayer);
        this.openCoordinateModal();
    }

    public clearGeometrySearch(): void {
        this.locationSearchService.searchLayer.clearLayers();
        this.locationSearchService.drawShape.next(undefined);
        this.locationSearchService.drawing = false;
        this.locationSearchService.updateMapInteractions.next(undefined);
    }

    /**
     * Finds the ESRI Routing from the given points and Draws it on the map
     */
    public findRoute(): void {
        this.searchingRoute = true;

        const start: IPoint = {
            latitude: this.routingStartMarker.getLatLng().lat,
            longitude: this.routingStartMarker.getLatLng().lng,
        };
        const stop: IPoint = {
            latitude: this.routingStopMarker.getLatLng().lat,
            longitude: this.routingStopMarker.getLatLng().lng,
        };

        this.locationSearchService
            .runEsriRouting(start, stop, this.locationSearchService.searchLayer)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res) => {
                this.searchingRoute = false;
                if (res.body.error) {
                    this.errorMessage = res.body.error.details[0];
                } else {
                    this.errorMessage = undefined;
                }

                // TODO: Find a cleaner way of setting the route
                const features = res.body.routes.features.map((f) => {
                    f.geometry.coordinates = f.geometry.paths;
                    return f;
                });

                const route = {
                    type: 'MultiLineString',
                    coordinates: features[0].geometry.coordinates,
                };

                this.locationSearchService.routeLayer.clearLayers();
                this.locationSearchService.routeLayer.addData(route as any);
                MapService.mapRef.fitBounds(this.locationSearchService.routeLayer.getBounds());
            });
    }

    /**
     * Clears the Route Selections including the drawn shape, marked points, and the route itself.
     */
    public clearRouteSelections(): void {
        this.errorMessage = undefined;
        this.locationSearchService.routeLayer.clearLayers();
        this.routingcoordinateGroup.get('start').patchValue({latitude: '', longitude: ''});
        this.routingcoordinateGroup.get('stop').patchValue({latitude: '', longitude: ''});
        this.locationSearchService.drawPoint.next({
            latitude: undefined,
            longitude: undefined,
            marker: this.routingStartMarker,
        });
        this.locationSearchService.drawPoint.next({
            latitude: undefined,
            longitude: undefined,
            marker: this.routingStopMarker,
        });
        this.clearGeometrySearch();
    }

    // Helper Methods
    public checkNumberInput(event: KeyboardEvent) {
        ApplicationConfig.numberInputValidation(event);
    }

    public togglePointSelection(isStart?: boolean): void {
        if (this.expanded.routing) {
            this.isSelectingStart = isStart;
            this.locationSearchService.executeBuffer = false;
            this.locationSearchService.routingState = true;
        } else {
            // Reset executeBuffer
            this.locationSearchService.executeBuffer = !this.expanded.utility;
            this.locationSearchService.routingState = false;
        }

        this.locationSearchService.pointSelection = !this.locationSearchService.pointSelection;
        if (this.locationSearchService.pointSelection) {
            this.locationSearchService.drawShape.next(undefined);
            this.setMarkerInteraction(false);
        } else {
            this.setMarkerInteraction(true);
        }

        this.locationSearchService.updateMapInteractions.next(undefined);
    }

    public updateDrawShape(event: MatButtonToggleChange): void {
        this.selectedShape = event.value;

        if (this.locationSearchService.drawing) {
            this.locationSearchService.drawShape.next(undefined);
            this.enableGeometrySearch();
        }
    }

    public openCoordinateModal(): void {
        this.dialog.open(CoordinateDataModalComponent, {
            width: ModalConfig.getModalWidth(),
            data: {
                headerText: 'Search Results',
            },
        });
    }

    public canSearchGeometry(): boolean {
        return this.locationSearchService.hasSearchFeatures() && !this.locationSearchService.pointSelection;
    }

    public enableGeometrySearch(): void {
        this.locationSearchService.pointSelection = false;
        this.locationSearchService.drawing = true;
        this.locationSearchService.updateMapInteractions.next(undefined);
        this.locationSearchService.drawShape.next(this.selectedShape);
    }

    /**
     * Checks if the start and stop points are valid to draw a route between
     * @returns Whether a route can be drawn
     */
    public canDrawRoute(): boolean {
        const start = this.routingcoordinateGroup.get('start');
        const stop = this.routingcoordinateGroup.get('stop');
        const startCheck =
            start.get('latitude').value.toString().trim().length > 0 && start.get('longitude').value.toString().trim().length > 0;
        const stopCheck =
            stop.get('latitude').value.toString().trim().length > 0 && stop.get('longitude').value.toString().trim().length > 0;
        return startCheck && stopCheck;
    }

    /**
     * Sets up a subsriber to watch the start and stop points to update the map markers
     */
    public setRoutingFormSub(): void {
        const drawPoint = (isStart: boolean, lat: number, lng: number) => {
            if (isNaN(lat) || isNaN(lng)) {
                return;
            }

            this.locationSearchService.drawPoint.next({
                latitude: lat,
                longitude: lng,
                marker: isStart ? this.routingStartMarker : this.routingStopMarker,
            });
            if (isStart) {
                this.routingStartMarker = this.locationSearchService.drawPoint.value.marker;
            } else {
                this.routingStopMarker = this.locationSearchService.drawPoint.value.marker;
            }
        };

        this.routingcoordinateGroup
            .get('start')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                const control = this.routingcoordinateGroup.get('start');
                if (
                    control.get('latitude').value.toString().trim().length > 0 &&
                    control.get('longitude').value.toString().trim().length > 0
                ) {
                    drawPoint(true, control.get('latitude').value, control.get('longitude').value);
                }
            });

        this.routingcoordinateGroup
            .get('stop')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                const control = this.routingcoordinateGroup.get('stop');
                if (
                    control.get('latitude').value.toString().trim().length > 0 &&
                    control.get('longitude').value.toString().trim().length > 0
                ) {
                    drawPoint(false, control.get('latitude').value, control.get('longitude').value);
                }
            });
    }

    /**
     * Turns off pointer events for any beautify marker icon. Allows the location search to propogate through the icon.
     * @param enable If true allow interaction, else disable interactions
     */
    private setMarkerInteraction(enable: boolean): void {
        const classes = ['.leaflet-map-pane', '.leaflet-marker-pane > img'];
        const markers = document.querySelectorAll(classes.join(','));

        markers.forEach((ele: HTMLDivElement) => {
            ele.style.pointerEvents = enable ? 'all' : 'none';
        });
    }

    public toggleUtilitySearch(): void {
        this.locationSearchService.executeBuffer = !this.expanded.utility;
        // this.locationSearchService.pointSelection = this.expanded.utility;
        this.locationSearchService.drawing = false;
        this.locationSearchService.updateMapInteractions.next(undefined);
    }

    public getUtilityInformation(point?: IPoint) {
        if (!point) {
            point = {latitude: this.coordinateGroup.controls.latitude.value, longitude: this.coordinateGroup.controls.longitude.value};
        }

        this.utilityLookupService.getUtilityInformation(point);

        this.dialog
            .open(UtilityLookupModalComponent, {
                data: {
                    manualLookup: false,
                    mapperPoint: point,
                },
            })
            .afterClosed()
            .subscribe(() => {
                this.utilityLookupService.clearPreviousState();
            });
    }

    public manualUtilitySearch(): void {
        this.utilityLookupService.clearPreviousState();

        this.dialog
            .open(UtilityLookupModalComponent, {
                data: {
                    manualLookup: true,
                },
            })
            .afterClosed()
            .subscribe(() => {
                this.utilityLookupService.clearPreviousState();
            });
    }
}
