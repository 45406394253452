
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenOutageDataZip {

    coveredCustomers: number;
    coveredCustomersType: string;
    customersOut: number;
    femaRegion: string;
    hasOverrideData: boolean;
    isModeled: boolean;
    modelCount: number;
    stateAbbreviation: string;
    stateId: number;
    timeStamp: moment.Moment;
    zipCode: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageDataZip: GenOutageDataZip);

    constructor( input?: GenOutageDataZip ) {
        if ( input && typeof input !== 'number' ) {
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.coveredCustomersType = input.coveredCustomersType; // string [string]
            this.customersOut = input.customersOut; // int [number]
            this.femaRegion = input.femaRegion; // string [string]
            this.hasOverrideData = input.hasOverrideData; // boolean [boolean]
            this.isModeled = input.isModeled; // boolean [boolean]
            this.modelCount = input.modelCount; // int [number]
            this.stateAbbreviation = input.stateAbbreviation; // string [string]
            this.stateId = input.stateId; // int [number]
            if ( input.timeStamp && typeof input.timeStamp === 'string' ) {
                this.timeStamp = moment(input.timeStamp).utc();
            } else {
                this.timeStamp = input.timeStamp;
            }
            this.zipCode = input.zipCode; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageDataZip[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageDataZip(a));
        } else {
          return [];
        }
    }
}

