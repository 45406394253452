import {AfterViewInit, Component, ElementRef, Inject, ViewChild, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ColumnDef} from '../../classes/column-def';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ModalConfig} from '../../../app/classes/modal-config';
import {ApplicationConfig} from '../../../app/classes/application-config';
import {map, startWith, switchMap, throttleTime} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, merge} from 'rxjs';

@Component({
    selector: 'eaglei-outage-chart-data-modal',
    templateUrl: './outage-chart-data-modal.component.html',
    styleUrls: ['../shared-modals.scss', './outage-chart-data-modal.component.scss'],
})
export class OutageChartDataModalComponent implements AfterViewInit {
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    private dialogData: any = inject(MAT_DIALOG_DATA);

    // Table Properties
    public readonly columnNames: string[] = [];
    public dataSource: MatTableDataSource<any>;
    public columnDefs: ColumnDef[] = this.dialogData?.columnDefs;
    public defaultSortDirection: string = '';
    public defaultSort: string = '';

    // Modal Properties
    public title: string = this.dialogData.title || 'Chart Data';
    public overrideColor = ApplicationConfig.currentUserPreferences.getValue().getOverrideColor();

    private data: any[] = this.dialogData?.tableData || [];
    public showMask: boolean;
    public maskText: string = 'Loading Data...';

    public totalDataLength: number = this.data.length;
    private tableDataUrl: string = this.dialogData?.tableDataUrl;
    private tableDataUrlParams: HttpParams = this.dialogData?.tableDataUrlParams;
    private httpClient = inject(HttpClient);

    constructor() {
        this.columnNames = this.columnDefs.map((cd) => cd.columnName);

        const defaultSort = this.columnDefs.find((cd) => cd.defaultSort);

        if (defaultSort) {
            this.defaultSortDirection = defaultSort.sortDirection;
            this.defaultSort = defaultSort.columnName;
        }
    }

    ngAfterViewInit() {
        this.initializeTable(this.data);
    }

    public updateData(data: any[]) {
        this.initializeTable(data);
    }

    /**
     * Sets up the table data and connects the sorting and paginator and sorting logic
     * @param data the data that will be displayed in the table.
     */
    private initializeTable(data: any[]): void {
        this.maskText = 'Loading Data...';
        this.showMask = data.length === 0;
        if (this.tableDataUrl) {
            this.sort.sortChange.subscribe(() => {
                this.paginator.pageIndex = 0;
            });

            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    startWith({}),
                    switchMap(() => {
                        return this.getUrlData(this.paginator.pageIndex + 1);
                    }),
                    map((retData: any) => {
                        if (retData === null) return [];
                        this.totalDataLength = retData.totalNumRows;
                        return retData.data;
                    })
                )
                .subscribe((chartDdata) => {
                    this.dataSource = chartDdata;
                    this.showMask = false;
                });
        } else {
            if (data.length === 0 && this.dialogData.noDataMask) {
                this.showMask = true;
                this.maskText = this.dialogData.noDataMask;
            }

            if (this.dataSource) {
                this.dataSource.data = data;
            } else {
                this.dataSource = new MatTableDataSource<any>(data);
            }
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.sortingLogic.bind(this);
            this.dataSource.sort = this.sort;
        }
    }

    private getUrlData(pageNumber: number): Observable<any> {
        this.tableDataUrlParams = this.tableDataUrlParams
            .delete('page')
            .delete('pageSize')
            .delete('sort')
            .set('page', pageNumber)
            .set('pageSize', this.paginator.pageSize)
            .set('sort', this.sort.active + this.sort.direction);
        return this.httpClient.get<any>(this.tableDataUrl, {params: this.tableDataUrlParams});
    }

    private sortingLogic(data: any, header: string): string | number {
        const cd = this.columnDefs.find((c) => c.columnName === header);
        return cd.sortValue(data);
    }
}
