import {Component, ElementRef, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CompactType, DisplayGrid, GridsterConfig, GridType} from 'angular-gridster2';
import {CustomLandingLayoutComponent} from 'frontend/src/app/landing/components/custom-landing-layout/custom-landing-layout.component';
import {LandingGridItem} from '../../../../landing/classes/landing-grid-item';
import {AuthenticationService} from '../../../../services/authentication.service';
import {User} from '../../../user/classes/user';

@Component({
    selector: 'eaglei-widget-list',
    templateUrl: './widget-list.component.html',
    styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent implements OnInit, OnChanges {
    @Input() gridOptions: GridsterConfig;
    @Input() dashboard: LandingGridItem[];
    @Input() eventId: BigInteger | number | undefined;

    private readonly GRID_BLOCK_SIZE = 160;
    private readonly GRID_GAP_SIZE = 15;

    private authService = inject(AuthenticationService);

    public config: GridsterConfig;

    private elementRef: ElementRef<HTMLElement> = inject(ElementRef);

    constructor() {
        this.config = {
            gridType: GridType.Fixed,
            itemResizeCallback: CustomLandingLayoutComponent.itemResize,
            compactType: CompactType.None,
            mobileBreakpoint: 600,
            fixedColWidth: 160,
            fixedRowHeight: 160,
            draggable: {
                enabled: true,
                dragHandleClass: 'draggable',
                ignoreContent: true,
                ignoreContentClass: 'ignore-content',
            },
            resizable: {
                enabled: false,
            },
            swap: true,
            pushItems: true,
            displayGrid: DisplayGrid.None,
        };
    }

    ngOnInit(): void {
        if (this.gridOptions) {
            this.config = {
                ...this.config,
                ...this.gridOptions,
            };
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dashboard) {
            const widgets: LandingGridItem[] = changes.dashboard.currentValue;
            this.dashboard = widgets.filter((item) => {
                const user: User = this.authService.authenticatedUser.getValue();

                const roles = item.detail?.widget?.roles;
                const communities = item.detail?.widget?.communityPermissions;

                if (roles?.length && communities?.length) {
                    return user.hasAnyRole(roles) || user.hasAnyDataAccess(communities);
                } else if (roles) {
                    return user.hasAnyRole(roles);
                } else if (communities) {
                    return user.hasAnyDataAccess(communities);
                }

                return true;
            });

            // I'm not sold on this approach, but when it's not moved to async the sizes get off when widgets
            // are dynamically removed. This may be something we can also fix with a signal input and an effect
            // once we move to Angular V17
            setTimeout(() => {
                let maxY = 0;
                let widgetSize = 0;
                this.dashboard.forEach((d) => {
                    maxY = Math.max(maxY, d.y);
                    if (maxY === d.y) widgetSize = Math.max(widgetSize, d.rows);
                });
                const sizeInPixels = (maxY + widgetSize) * this.GRID_BLOCK_SIZE + (maxY + widgetSize) * this.GRID_GAP_SIZE;
                this.elementRef.nativeElement.style.height = `${sizeInPixels}px`;
            }, 0);
        }
    }
}
