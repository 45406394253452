<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend status-legend">
            <div>
                <div class="circle-container">
                    <div class="status block open"></div>
                    <span class="label"> Open</span>
                </div>
                <div class="circle-container">
                    <div class="status block closed"></div>
                    <span class="label"> Closed</span>
                </div>
                <div class="circle-container">
                    <div class="status block restricted"></div>
                    <span class="label"> Restrictions</span>
                </div>
                <div class="circle-container">
                    <div class="status block both"></div>
                    <span class="label"> Closed & Restrictions</span>
                </div>
                <div class="circle-container">
                    <div class="status block none"></div>
                    <span class="label"> 0 Subports open</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>
