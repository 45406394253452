import {GenContactType} from 'frontend/generated/serverModels/GenContactType';
import {GenRRCCPersonnel} from '../../../generated/serverModels/GenRRCCPersonnel';
import {RRCCContact} from './rrcc-contact';

export class RRCCPersonnel extends GenRRCCPersonnel {
    public tmpValue: string;

    public contactsInfo: RRCCContact[] = [];
    public emails: RRCCContact[] = [];
    public phones: RRCCContact[] = [];

    constructor(personnel?: RRCCPersonnel) {
        super(personnel);

        if (personnel) {
            this.tmpValue = personnel.name;

            this.contactsInfo = personnel?.contacts?.map((c) => new RRCCContact(c as any));
            this.contactsInfo?.forEach((c) => {
                switch (c.type) {
                    case GenContactType.EMAIL:
                        this.emails.push(c);
                        return;
                    case GenContactType.PHONE_NUMBER:
                        this.phones.push(c);
                        return;
                }
            });
        }
    }
}
