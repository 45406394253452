import {AfterViewInit, Component, ElementRef, inject, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {GenOutageAggregationLevel} from '../../../../generated/serverModels/GenOutageAggregationLevel';
import {EagleiBaseChart} from '../../../app/classes/charts/base-chart';
import {ChartDataService} from '../../services/chart-data.service';
import {GenAggregationLevel} from '../../../../generated/serverModels/GenAggregationLevel';
import {ApplicationConfig} from '../../../app/classes/application-config';
import {LegendMetric} from '../../enums/legend-metric.enum';
import {LayerStyleService} from '../../../app/modules/layer/services/layer-style.service';
import * as moment from 'moment';
import {DataPoint} from '../../../app/classes/data-point';
import {PopoverElement} from '../../../app/classes/popover-element';
import {Report} from '../../../app/modules/report/classes/report';
import {BehaviorSubject, merge, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {State} from '../../../app/modules/outage/classes/state';
import {HttpParams} from '@angular/common/http';
import {ColumnDef} from '../../classes/column-def';
import {OutageChartData} from '../../../app/modules/outage/interfaces/outage-chart-data.interface';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ModalConfig} from '../../../app/classes/modal-config';
import {OutageChartDataModalComponent} from '../../modals/outage-chart-data-modal/outage-chart-data-modal.component';
import {AggregationLevelPipe} from '../../pipes/aggregation-level.pipe';
import {StateNamePipe} from '../../pipes/state-name.pipe';
import {AsyncPipe, NgIf} from '@angular/common';
import {FileDownload} from '../../../app/classes/file-download';
import {DataService} from '../../../app/services/data.service';

interface ChartData {
    total: number;
    percent: number;
    customersModeled: number;
    customersOut: number;
    timeStamp: moment.Moment;
}

@Component({
    standalone: true,
    selector: 'eaglei-nom-chart',
    imports: [AsyncPipe, NgIf],
    templateUrl: './nom-chart.component.html',
    styleUrls: ['./nom-chart.component.scss'],
})
export class NomChartComponent implements AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('chartTarget', {static: true}) chartTarget: ElementRef;
    @Input() aggregationLevel: GenAggregationLevel | GenOutageAggregationLevel = GenAggregationLevel.state;
    @Input() startDate: moment.Moment = moment().subtract(1, 'days');
    @Input() endDate: moment.Moment = moment();
    @Input() states: State[] = ApplicationConfig.currentUserPreferences.getValue().getStates();

    private chartService = inject(ChartDataService);
    private dialog = inject(MatDialog);

    private aggregationPipe = new AggregationLevelPipe();
    private stateNamePipe = new StateNamePipe();

    public loading$ = new BehaviorSubject<boolean>(true);
    private destroy$ = new Subject();
    private filterChange$ = new Subject<void>();

    public baseChart = new EagleiBaseChart();

    ngAfterViewInit() {
        this.initializeChart();

        merge(this.filterChange$, ApplicationConfig.showOverrideColor)
            .pipe(debounceTime(50), takeUntil(this.destroy$))
            .subscribe(() => this.getChartData()); // Instead of recalling, on color change, we should just rerender

        this.filterChange$.next();
    }

    ngOnChanges() {
        this.filterChange$.next();
    }

    ngOnDestroy() {
        this.destroy$.complete();
        this.destroy$.unsubscribe();
    }

    private initializeChart(): void {
        this.baseChart.interactivePopover = true;
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Customers without power');
    }

    private getChartData(): void {
        this.loading$.next(true);
        this.chartService
            .getNomChartDataPoints(this.aggregationLevel, this.states, this.startDate, this.endDate)
            .pipe(debounceTime(250))
            .subscribe({
                next: (dataPoints: DataPoint<ChartData>[]) => {
                    this.renderChart(dataPoints);
                },
                error: (error: any) => {
                    console.error('there was an error loading nom chart', error);
                },
            });
    }
    // , takeUntil(this.destroy$)
    private getPopoverData(dataPoint: DataPoint<ChartData>): PopoverElement[] {
        const popover: PopoverElement[] = [];

        // Adding the title
        popover.push(new PopoverElement().setTitle().setValue(Report.momentPipe.transform(dataPoint.x)));

        popover.push(
            new PopoverElement().setLabel('Customers Out').setValue(Report.numberPipe.transform(dataPoint.data.customersOut).toString())
        );

        if (dataPoint.data.total !== undefined) {
            popover.push(
                new PopoverElement()
                    .setLabel('Total Customers')
                    .setValue(Report.numberPipe.transform(dataPoint.data.total).toString())
                    .setSubValue(dataPoint.data.customersModeled ? '(Modeled)' : '(Collected)')
                    .setSubValueClass('county-customer')
            );
        }

        if (dataPoint.data.percent !== undefined) {
            const percent = !!dataPoint.data.total
                ? Report.percentPipe.transform(dataPoint.data.percent, '1.2-2').toString()
                : 'Not Available';
            popover.push(new PopoverElement().setLabel('Percent Out').setValue(percent));
        }

        const onBarClick = () => {
            this.openPopoverModal(dataPoint.x as number, ApplicationConfig.roundMinute(dataPoint.data.timeStamp).format());
        };

        popover.push(new PopoverElement().setLabel('View Data').setValue(dataPoint.x.toString()).setIsModal().setOnClick(onBarClick));
        return popover;
    }

    private renderChart(dataToRender: DataPoint<ChartData>[]): void {
        const xValues: number[] = [];
        const yValues: any[] = [];

        const preferences = ApplicationConfig.currentUserPreferences.getValue();

        dataToRender.forEach((dp) => {
            xValues.push(dp.x as number);
            const prop = preferences.getLegendMetric() === LegendMetric.PERCENTAGE ? dp.data.percent : dp.data.customersOut;
            const color = LayerStyleService.colorByCount(prop);

            const yData = {
                value: dp.data.customersOut,
                itemStyle: {
                    color,
                },
                popoverData: this.getPopoverData(dp),
                exportedData: {
                    x: dp.x,
                    y: dp.y,
                    total: dp.data.total,
                    percent: dp.data.percent,
                },
            };

            yValues.push(yData);
        });

        const validData = yValues.some((data) => !!data.value);

        this.baseChart.eChartOptions.xAxis['data'] = xValues;
        this.baseChart.eChartOptions.series = [
            {
                type: 'bar',
                data: validData ? yValues : [],
            },
        ];

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);
        this.loading$.next(false);
    }

    public openPopoverModal(value: number, runStartTime: string) {
        const modalUrl = `/api/outagesummary/nom-chart-table-data`;
        let modalUrlParams = new HttpParams().set('type', this.aggregationLevel.toString()).set('timestamp', runStartTime);

        if (this.states.length !== 0) {
            modalUrlParams = modalUrlParams.append('state', this.states.map((state) => state.abbreviation).join());
        }

        const nameColumns: ColumnDef[] = [
            new ColumnDef(
                this.aggregationPipe.transform(this.aggregationLevel),
                (s) => s.zipCode || s.countyName || this.stateNamePipe.transform(s.stateAbbreviation)
            ),
        ];

        if (this.aggregationLevel !== GenOutageAggregationLevel.state) {
            const titleColumn = new ColumnDef('State', (s) => this.stateNamePipe.transform(s.stateAbbreviation));
            nameColumns.push(titleColumn);
        }

        const getPercent = (s: OutageChartData) => {
            const percent = s.customersOut / s.coveredCustomers;
            return isFinite(percent) ? Report.percentPipe.transform(percent, '1.2-2') : 'Not Available';
        };

        const outageColumns: ColumnDef<OutageChartData>[] = [
            new ColumnDef('Fips Code', (s) => s.fipsCode),
            new ColumnDef('Customers Out', (s) => Report.numberPipe.transform(s.customersOut))
                .setDefaultSort(true)
                .sort((s) => s.customersOut),
            new ColumnDef('Total Customers', (s: OutageChartData) => {
                if (s.coveredCustomers === undefined) return 'Not Available';
                return `${Report.numberPipe.transform(s.coveredCustomers)}`;
            })
                .sort((s) => s.coveredCustomers)
                .setSubscript((s) => (s.modelCount && s.modelCount > 0 ? '(Modeled)' : '(Collected)'), 'county-customer'),
            new ColumnDef('Percent Out', (s: OutageChartData) => getPercent(s)).sort((s) => getPercent(s)),
        ];

        if (this.aggregationLevel === GenOutageAggregationLevel.zip) {
            outageColumns.splice(0, 1);
        }

        const config: MatDialogConfig = {
            ...ModalConfig.defaultConfig,
            autoFocus: false,
            data: {
                columnDefs: nameColumns.concat(outageColumns),
                tableDataUrl: modalUrl,
                tableDataUrlParams: modalUrlParams,
                title: `Outages for ${Report.momentPipe.transform(value)}`,
            },
        };

        this.dialog.open(OutageChartDataModalComponent, config);
    }

    /**
     * Returns the appropriate mask text, for loading, no filters, or no data
     */
    public getMaskText(): string {
        let text = 'Loading...';

        if (!this.loading$.getValue()) {
            if (!this.baseChart.hasData()) {
                text = 'No Data Available';
            } else if (this.states.length === 0) {
                text = 'No Filters Selected';
            }
        }

        return text;
    }

    // This is called from external components.
    public exportChartAsCsv(name = 'outageTrend', attributionUrl?: string) {
        let data = '';
        const aggPipe = new AggregationLevelPipe();
        const columns = ['Date', 'Customers Out', 'Total Customers', 'Percent Out'];

        data += FileDownload.formatCsvCell(`Trend for ${aggPipe.transform(this.aggregationLevel)}`) + ',';
        data += FileDownload.formatCsvCell(`Active Filters: ${this.states.map((f) => f.abbreviation)}`) + '\n';
        data += columns.join() + '\n\n';

        this.baseChart.eChart.getOption().series.forEach((series) => {
            series.data.forEach((point) => {
                data +=
                    [
                        FileDownload.formatCsvCell(Report.momentPipe.transform(point.exportedData.x)),
                        FileDownload.formatCsvCell(Report.numberPipe.transform(point.exportedData.y).toString()),
                        FileDownload.formatCsvCell(Report.numberPipe.transform(point.exportedData.total).toString()),
                        FileDownload.formatCsvCell(
                            Report.percentPipe
                                .transform(point.exportedData.percent == null ? 0 : point.exportedData.percent, '1.2-2')
                                .toString()
                        ),
                    ].join() + '\n';
            });
        });

        FileDownload.downloadCSV(name, data, attributionUrl);
    }

    public exportChartAsPng(title?: string, filename = 'outageChart', attributionUrl?: string) {
        if (!title) {
            const allStates = this.states.length === DataService.states.getValue().length;
            title = `Outages for ${allStates ? 'All' : 'Selected'} States`;
        }
        FileDownload.exportChartAsPNG(this.baseChart, filename, title, attributionUrl);
    }
}
